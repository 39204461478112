import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyTeamDailyUpdate from './MyTeamDailyUpdate';
import MyTeamProjects from './MyTeamProjects';
import { boundaryContext } from "../../../App";
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { getRequest } from '../../../components/axiosClient';
import { GET_ASSETS, GET_EMPLOYEE_NAME, GET_MY_TEAM_PENDING_REQUEST_COUNT } from '../../../components/utils';
import MyTeamTask from './MyTeamTask';
import MyTeamTaskRequest from './MyTeamTaskRequest';

function MyTeamDetail() {

    const { setMyTeamEmployeeName, myTeamActiveRoute, setMyTeamActiveRoute } = useContext(boundaryContext);
    const navigate = useNavigate();

    const [count, setCount] = useState();
    const goBack = () => {
        navigate("/my-team");
    };
    const handleTabChange = (e) => {
        // setPage(1);
        setActiveTab(e.target.name);
        setMyTeamActiveRoute(e.target.name);
    };

    const [activeTab, setActiveTab] = useState(myTeamActiveRoute || 'time-sheet');
    const { id } = useParams();

    const getEmployeeName = async () => {
        try {
            let url = `${GET_EMPLOYEE_NAME}?user_id=${id}`
            const res = await getRequest(url, null, navigate);
            if (res && res.data) {
                let name = res.data.data.first_name + ' ' + res.data.data.last_name;
                setMyTeamEmployeeName(name);
            }

        } catch (error) {
            errorNotification(error);
        }
    };
    const getRequestCount = async () => {
        try {
            let url = `${GET_MY_TEAM_PENDING_REQUEST_COUNT}?user_id=${id}`
            const res = await getRequest(url, null, navigate);
            if (res && res.data) {
                setCount(res.data.data.pendingRequest);
            }

        } catch (error) {
            errorNotification(error);
        }
    };

    useEffect(() => {
        getEmployeeName();
        getRequestCount();
    }, [])

    return (
        <div className='bg-white p-2 rounded-xl'>
            <div className="go__back__btn flex flex-row justify-start items-center my-2 gap-1 pl-4">
                <div className="">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer !w-8" />
                </div>
            </div>
            <div className='px-4'>
                <ul className="nav nav-pills mb-3 mt-2 border-b-2 flex flex-row" id="pills-tab" role="tablist">
                    <li className="nav-item mb-[-2px]" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "time-sheet" ? "active" : ""}`}
                            id="pills-time-sheet-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-time-sheet"
                            type="button"
                            role="tab"
                            aria-controls="pills-time-sheet"
                            aria-selected="true"
                            onClick={(e) => handleTabChange(e)}
                            name="time-sheet"
                        >
                            Time Sheet
                        </button>
                    </li>
                    <li className="nav-item mb-[-2px]" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "projects" ? "active" : ""}`}
                            id="pills-projects-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-projects"
                            type="button"
                            role="tab"
                            aria-controls="pills-projects"
                            aria-selected="false"
                            name="projects"
                            onClick={(e) => handleTabChange(e)}
                        >
                            Projects
                        </button>
                    </li>
                    <li className="nav-item mb-[-2px]" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "task" ? "active" : ""}`}
                            id="pills-task-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-task"
                            type="button"
                            role="tab"
                            aria-controls="pills-task"
                            aria-selected="false"
                            name="task"
                            onClick={(e) => handleTabChange(e)}
                        >
                            Tasks
                        </button>
                    </li>
                    <li className="nav-item mb-[-2px]" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "task-request" ? "active" : ""}`}
                            id="pills-task-request-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-task-request"
                            type="button"
                            role="tab"
                            aria-controls="pills-task-request"
                            aria-selected="false"
                            name="task-request"
                            onClick={(e) => handleTabChange(e)}
                        >
                            Task Request
                            {
                                count > 0 &&
                                <span className="bg-red-500 inline-block text-white rounded-full h-4 w-4 text-center text-xs ml-1">{count}</span>
                            }
                        </button>
                    </li>
                </ul>
            </div>

            <div className="program_scroll">
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className={`tab-pane fade ${activeTab === "time-sheet" ? "show active" : ""
                            }`}
                        id="pills-time-sheet"
                        role="tabpanel"
                        aria-labelledby="pills-time-sheet-tab"
                    >
                        <div>
                            {activeTab === "time-sheet" && <MyTeamDailyUpdate />}
                        </div>
                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === "projects" ? "show active" : ""
                            }`}
                        id="pills-projects"
                        role="tabpanel"
                        aria-labelledby="pills-projects-tab"
                    >
                        {activeTab === "projects" && <MyTeamProjects />}
                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === "task" ? "show active" : ""
                            }`}
                        id="pills-task"
                        role="tabpanel"
                        aria-labelledby="pills-task-tab"
                    >
                        {activeTab === "task" && <MyTeamTask />}
                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === "task-request" ? "show active" : ""
                            }`}
                        id="pills-task-request"
                        role="tabpanel"
                        aria-labelledby="pills-task-request-tab"
                    >
                        {activeTab === "task-request" && <MyTeamTaskRequest setCount={setCount}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTeamDetail