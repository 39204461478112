import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "react-select";
import { GET_ROLE, GET_TECHNOLOGY, GET_DEPARTMENT, GET_BRANCH, EDIT_EMPLOYEES, GET_EMPLOYEE_BY_ID, GET_EMPLOYEE_DROPDOWN_LIST } from "../../../components/utils";
import {
    errorNotification,
    successNotification,
} from "../../../components/toast-notification/common-toast";
import { getRequest, patchRequest } from "../../../components/axiosClient";

const EditEmployee = () => {
    const [address, setAddress] = useState("");
    const addressRef = useRef(null);
    const navigate = useNavigate();
    const [sortDepartment, setSortDepartment] = useState([]);
    const [sortBranch, setSortBranch] = useState([]);
    const [sortTechnology, setSortTechnology] = useState([]);
    const [reportingPersonList, setReportingPersonList] = useState([]);
    const [sortRole, setSortRole] = useState([]);
    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

    const goBack = () => {
        navigate(-1);
    };

    // Adjust the textarea height based on its scroll height
    useEffect(() => {
        if (addressRef.current) {
            addressRef.current.style.height = "auto";
            addressRef.current.style.height = `${addressRef.current.scrollHeight}px`;
        }
    }, [address]);

    // Get employee details
    const getEmployeeDetails = async () => {
        try {
            const userId = id;
            const res = await getRequest(
                `${GET_EMPLOYEE_BY_ID}?user_id=${userId}`,
                null,
                navigate
            );
            if (res?.data && res?.data.data) {
                const employeeData = res.data.data;
                setFormData({
                    user_id: employeeData?.id,
                    employee_id: employeeData?.employee_id || "",
                    first_name: employeeData?.first_name || "",
                    last_name: employeeData?.last_name || "",
                    email: employeeData?.email || "",
                    contact_number: employeeData?.contact_number || "",
                    birth_date: employeeData?.birth_date || "",
                    emergency_contact_name: employeeData?.emergency_contact_name || "",
                    emergency_contact_no: employeeData?.emergency_contact_no || "",
                    joining_date: employeeData?.joining_date || "",
                    leaving_date: employeeData?.leaving_date || "",
                    address: employeeData?.address || "",
                    role_id: employeeData?.role?.id || "",
                    department_id: employeeData?.department?.id || "",
                    branch_id: employeeData?.branch?.id || "",
                    technology_id: employeeData?.technology?.id || "",
                    reporting_person_id: employeeData?.reporting_person?.id || "",
                });
                setAddress(employeeData.address);
            } else {
                errorNotification("Failed to fetch employee details.");
            }
        } catch (error) {
            errorNotification(error);
        }
    };

    // Get all Role list API call
    const getAllRole = async () => {
        try {
            const res = await getRequest(GET_ROLE, null, navigate);
            const rolelist = res.data.data.map((item) => {
                return { value: item.id, label: item?.name };
            });
            setSortRole(rolelist);
        } catch (error) {
            errorNotification(error);
        }
    };

    // Get all Technology list API call
    const getAllTechnology = async () => {
        try {
            const res = await getRequest(GET_TECHNOLOGY, null, navigate);
            const technologylist = res.data.data.map((item) => {
                return { value: item.id, label: item?.name };
            });
            setSortTechnology([{ value: null, label: "None" }, ...technologylist]);
        } catch (error) {
            errorNotification(error);
        }
    };

    const getAllReportingPerson = async () => {
        try {
            setLoading(true);
            const res = await getRequest(
                `${GET_EMPLOYEE_DROPDOWN_LIST}`,
                null,
                navigate
            );
            const list = res.data.data.map((item) => {
                let name = item.first_name + " " + item.last_name;
                return { value: item.id, label: name };
            });
            setReportingPersonList([{ value: null, label: "None" }, ...list]);
            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    // Get all department list API call
    const getAllDepartment = async () => {
        try {
            const res = await getRequest(GET_DEPARTMENT, null, navigate);
            const dataList = res.data.data.map((item) => {
                return { value: item.id, label: item?.department_name };
            });
            setSortDepartment(dataList);
        } catch (error) {
            errorNotification(error);
        }
    };

    // Get all branch list API call
    const getAllBranch = async () => {
        try {
            const res = await getRequest(GET_BRANCH, null, navigate);
            const branchlist = res.data.data.map((item) => {
                return { value: item.id, label: item?.branch_name };
            });
            setSortBranch(branchlist);
        } catch (error) {
            errorNotification(error);
        }
    };

    const editEmployeeData = async (payload) => {
        try {
            const updatedPayload = { ...payload };

            const res = await patchRequest(`${EDIT_EMPLOYEES}`, updatedPayload, navigate);
            if (res.type === 1) {
                if (res.response.data.message) {
                    successNotification(res.response.data.message)
                    getEmployeeDetails();
                }
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
            errorNotification(error);
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        editEmployeeData(formData);
    };

    useEffect(() => {
        getEmployeeDetails();
        getAllDepartment();
        getAllBranch();
        getAllRole();
        getAllTechnology();
        getAllReportingPerson();
    }, []);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle select change
    const handleSelectChange = (name, selectedOption) => {
        setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : null });
    };

    return (
        <div className="admin__program__manage admin__program__details__section admin_audit">
            <div className="go__back__btn flex justify-between mt-1">
                <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer" />
            </div>
            <form className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-2 add__candidate-leads__form" onSubmit={handleSubmit}>
                <div>
                    <label className="block mb-1 font-semibold">Employee ID</label>
                    <input
                        type="text"
                        name="employee_id"
                        value={formData.employee_id}
                        onChange={handleInputChange}
                        className="candidate-leads-name text-capitalize"
                        placeholder="Employee ID"
                        autoComplete="off"
                        readOnly
                    />
                </div>
                <div></div>
                <div>
                    <label className="block mb-1 font-semibold">First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        className="candidate-leads-name text-capitalize"
                        placeholder="First Name"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        className="candidate-leads-name text-capitalize"
                        placeholder="Last Name"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="candidate-leads-name"
                        placeholder="Email Address"
                        autoComplete="off"
                        readOnly
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Report to</label>
                    <Select
                        options={reportingPersonList}
                        className="basic-single text-capitalize Employ_Country"
                        classNamePrefix="select"
                        placeholder="Select Reporting person"
                        name="reporting_person_id"
                        value={reportingPersonList.find(option => option.value === formData.reporting_person_id)}
                        onChange={(selectedOption) => handleSelectChange("reporting_person_id", selectedOption)}
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Contact No.</label>
                    <input
                        type="number"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleInputChange}
                        className="candidate-leads-name text-capitalize"
                        placeholder="Contact No"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Birth Date</label>
                    <input
                        type="date"
                        name="birth_date"
                        value={formData.birth_date}
                        onChange={handleInputChange}
                        className="candidate-leads-name"
                        placeholder="Birth Date"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Emergency Contact Name</label>
                    <input
                        type="text"
                        name="emergency_contact_name"
                        value={formData.emergency_contact_name}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded"
                        placeholder="Emergency Contact Name"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Emergency No.</label>
                    <input
                        type="number"
                        name="emergency_contact_no"
                        value={formData?.emergency_contact_no}
                        onChange={handleInputChange}
                        className="candidate-leads-name text-capitalize"
                        placeholder="Emergency No"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Department</label>
                    <Select
                        options={sortDepartment}
                        className="basic-single text-capitalize Employ_Country"
                        classNamePrefix="select"
                        placeholder="Select Department"
                        name="department_id"
                        value={sortDepartment.find(option => option.value === formData.department_id)}
                        onChange={(selectedOption) => handleSelectChange("department_id", selectedOption)}
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Branch</label>
                    <Select
                        options={sortBranch}
                        className="basic-single text-capitalize Employ_Country"
                        classNamePrefix="select"
                        placeholder="Select Branch"
                        name="branch_id"
                        value={sortBranch.find(option => option.value === formData.branch_id)}
                        onChange={(selectedOption) => handleSelectChange("branch_id", selectedOption)}
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Employee Role</label>
                    <Select
                        options={sortRole}
                        className="basic-single text-capitalize Employ_Country"
                        classNamePrefix="select"
                        placeholder="Select Employee Role"
                        name="role_id"
                        value={sortRole.find(option => option.value === formData.role_id)}
                        onChange={(selectedOption) => handleSelectChange("role_id", selectedOption)}
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Technology</label>
                    <Select
                        options={sortTechnology}
                        className="basic-single Employ_Country"
                        classNamePrefix="select"
                        placeholder="Select Technology"
                        name="technology_id"
                        value={sortTechnology.find(option => option.value === formData.technology_id)}
                        onChange={(selectedOption) => handleSelectChange("technology_id", selectedOption)}
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Joining Date</label>
                    <input
                        type="date"
                        name="joining_date"
                        value={formData.joining_date}
                        onChange={handleInputChange}
                        className="candidate-leads-name"
                        placeholder="Joining Date"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label className="block mb-1 font-semibold">Leaving Date</label>
                    <input
                        type="date"
                        name="leaving_date"
                        value={formData.leaving_date}
                        onChange={handleInputChange}
                        className="candidate-leads-name"
                        placeholder="Leaving Date"
                        autoComplete="off"
                    />
                </div>
                <div>
                    <h6 className="block mb-1 font-semibold">Address</h6>
                    <textarea
                        className="candidate-leads-name"
                        placeholder="Enter Address"
                        name="address"
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value)
                            setFormData({ ...formData, address: e.target.value })

                        }}
                        ref={addressRef}
                        style={{ overflow: "hidden" }}
                    />
                </div>
                <div>
                    <button
                        type="submit"
                        className="bg-[#f47e53] mt-4 text-[#fff] w-[100%] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditEmployee;
