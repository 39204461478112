import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../components/loader/LoadingSpinner'
import { useNavigate, useSearchParams } from 'react-router-dom';
import './MyTeam.css';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import CustomPagination from '../../../components/common/CustomPagination';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { getRequest } from '../../../components/axiosClient';
import { GET_MY_TEAM } from '../../../components/utils';

function MyTeam() {

  const [loading, setLoading] = useState(false);
  const [pageInformation, setPageInformation] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const navigate = useNavigate();
  const pageSize = 10;
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = searchParams.get('pageno');
  const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);

  useEffect(() => {
    getAllEmployee();
  }, [page])

  const getAllEmployee = async () => {
    try {
      setLoading(true);
      let apiUrl = `${GET_MY_TEAM}?page=${page}&limit=${pageSize}`;
      const res = await getRequest(
        apiUrl,
        null,
        navigate
      );
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setEmployeeList(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
      } else {
        errorNotification(res.errormessage);
        setEmployeeList([]);
      }
      setLoading(false);
    }
    catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };



  const handleView = (id) => {
    navigate(`/my-team/my-team-details/${id}`);
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="myTeam_section bg-[#fff] rounded-[12px] p-[10px]">
      {employeeList.length === 0 ? (
        <div className="no__transcation">
          <img src={noDataFound} alt={noDataFound} loading="lazy" />
        </div>
      ) : (
        <div className="myTeam_listing">
          <div className="myTeam_data pb-4">
            <div className="d-flex flex-column flex-md-row align-items-start justify-content-between min-h-[600px]">
              <table className="table myTeam_list_table table-responsive w-full">
                <thead className="report__header__border">
                  <tr className="border-style">
                    <th scope="col" className="font__weight header__first__child w-[12%]">
                      ID
                    </th>
                    <th scope="col" className="font__weight w-[18%]">
                      Full Name
                    </th>
                    <th scope="col" className="font__weight w-[18%]">
                      Technology
                    </th>
                    <th scope="col" className="font__weight w-[18%]">
                      Branch
                    </th>
                    <th scope="col" className="font__weight w-[42%]"></th>
                  </tr>
                </thead>
                <tbody>
                  {employeeList.map((item, index) => (
                    <tr key={index} className={``}>
                      <td className="!text-[#f47e53] table_tr_style align-middle" >
                        {item?.employee_id}
                      </td>
                      <td className="table_tr_style align-middle text-capitalize">
                        {item?.first_name} {item?.last_name}
                      </td>
                      <td className="align-middle">
                        {item?.technology ? item.technology.name : '--'}
                      </td>
                      <td className="align-middle">
                        {item?.branch ? item.branch.branch_name : '--'}
                      </td>

                      <td className="text-center align-middle !border-b-0 d-flex justify-content-end">
                        {/* {role_accesses && ( */}
                        <>
                          <button
                            className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                            onClick={() => handleView(item?.id)}
                          >
                            View
                          </button>
                        </>
                        {/* )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <CustomPagination
              page={page}
              pageInformation={pageInformation}
              setPage={setPage}
              totalPages={pageInformation?.last_page}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MyTeam