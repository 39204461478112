import React, { useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../../../components/common/CustomPagination";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import Macmini_icon from '../../../components/assets/Mac_mini_icon.svg'
import { FaLaptop } from "react-icons/fa";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { BsCpu, BsDisplay } from "react-icons/bs";
import "./assets.css";
import { ADD_ASSETS, GET_ASSETS, GET_BRANCH, GET_EMPLOYEE_LEST_FOR_ASSETS } from "../../../components/utils";
import { getRequest, postRequest } from "../../../components/axiosClient";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";

const Assets = () => {
  const [loading, setLoading] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [pageInformation, setPageInformation] = useState({});
  const [listShow, setListShow] = useState(false);
  const [formFields, setFormFields] = useState({
    code: "",
    item: "",
    location: "",
    assigned_id: "",
    os_version: "",
    processor: "",
    graphic_card: "",
    ram: "",
    hard_disk: "",
    model: "",
    display_type: "",
    mobile_company: ""
  });

  const [branchList, setBranchList] = useState([]);
  const [branchFilterList, setBranchFilterList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const [open, setOpen] = useState(false);
  const pageSize = 10;
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = searchParams.get('pageno');
  const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);

  const allocationParams = searchParams.get('allocation');
  const [allocationFilter, setAllocationFilter] = useState(allocationParams ? allocationParams : null);

  const locationParams = searchParams.get('location');
  const [locationFilter, setLocationFilter] = useState(locationParams ? parseInt(locationParams) : null);

  const itemParams = searchParams.get('item');
  const [itemFilter, setItemFilter] = useState(itemParams ? itemParams : null);

  const osParams = searchParams.get('os');
  const [osFilter, setOsFilter] = useState(osParams ? osParams : null);

  useEffect(() => {
    getAllDepartment();
    getAllEmployee();
  }, [])

  useEffect(() => {
    getAllAssets(allocationFilter, locationFilter, itemFilter, osFilter);
  }, [page, allocationFilter, locationFilter, itemFilter, osFilter]); //filter dependencies

  const handleAdd = () => {
    setListShow(true);
  };

  const handleView = (id) => {
    navigate(`/assets/assets-details/${id}`);
  };

  const role_accesses =
    JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "My Task")?.access_type !== "Read";

  const getAllAssets = async (allocationFilter, locationFilter, itemFilter, osFilter) => {
    try {
      setLoading(true);
      let apiUrl = `${GET_ASSETS}?page=${page}&limit=${pageSize}`;
      if (allocationFilter && allocationFilter !== "All") {
        apiUrl += `&allocation=${allocationFilter}`;
      }
      if (locationFilter && locationFilter !== "All") {
        apiUrl += `&location=${locationFilter}`;
      }
      if (itemFilter && itemFilter !== "All") {
        apiUrl += `&item=${itemFilter}`;
      }
      if (osFilter && osFilter !== "All") {
        apiUrl += `&OS=${osFilter}`;
      }
      const res = await getRequest(apiUrl, null, navigate);
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setAssetsList(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
      } else {
        setAssetsList([]);
      }
    } catch (error) {
      errorNotification(error.message || "Failed to fetch project leads");
    } finally {
      setLoading(false);
    }
  };

  const addAssets = async (payload) => {
    try {
      const res = await postRequest(ADD_ASSETS, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          getAllAssets();
          successNotification("Assets Added Successfully");
          setListShow(false);
          setFormFields({
            code: "",
            item: "",
            location: "",
            assigned_id: "",
            OS: "",
            os_version: "",
            processor: "",
            graphic_card: "",
            ram: "",
            hard_disk: "",
            model: "",
            display_type: "",
            mobile_company: "",
            note: "",
          });
          setAllocationFilter(null);
          setLocationFilter(null);
          setItemFilter(null);
          setOsFilter(null);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };



  const handleFormSubmit = () => {
    const { code, item, location } = formFields;

    if (!code) {
      errorNotification('Code is required.');
      return;
    }

    if (!item) {
      errorNotification('Item is required.');
      return;
    }

    if (!location) {
      errorNotification('Location is required.');
      return;
    }

    const assetData = Object.fromEntries(
      Object.entries(formFields).map(([key, value]) => [key, value === "" ? null : value])
    );
    addAssets(assetData);
  };

  const getAllDepartment = async () => {
    try {
      const res = await getRequest(GET_BRANCH, null, navigate);
      const dataList = res.data.data.map((item) => {
        return { value: item.id, label: item?.branch_name };
      });
      setBranchList(dataList);
      setBranchFilterList(dataList);
      setBranchFilterList(prop => {
        return [...prop, { value: "All", label: "All" }];
      })
    } catch (error) {
      errorNotification(error);
    }
  };

  const getAllEmployee = async () => {
    try {
      const res = await getRequest(GET_EMPLOYEE_LEST_FOR_ASSETS, null, navigate);
      const dataList = res.data.data.map((item) => {
        return { value: item.id, label: item?.first_name + ' ' + item?.last_name };
      });
      dataList.unshift({ value: null, label: "None" });
      setEmployeeList(dataList);

    } catch (error) {
      errorNotification(error);
    }
  };

  const customIcons = {
    CPU: <BsCpu />,
    "Mac Mini": <img src={Macmini_icon} alt="Mac mini" style={{ width: '20px', height: '20px' }} />,
    Laptop: <FaLaptop />,
    Display: <BsDisplay />,
    Mobile: <CiMobile3 />,
    IMac: <MdOutlineScreenshotMonitor />
  };

  const typeArray = [
    "Display",
    "Laptop",
    "CPU",
    "Mac Mini",
    "Mobile",
    "IMac"
  ]

  const typeOptions = typeArray.map((props) => ({
    label: props,
    value: props,
    icon: customIcons[props]
  }));

  const customFormatOptionLabel = ({ label, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{icon}</span>
      <span>{label}</span>
    </div>
  );

  const laptopModalArray = [
    { model: "Macbook Air", company: "Apple" },
    { model: "Macbook Pro", company: "Apple" },
    { model: "Macbook Max", company: "Apple" },
    { model: "Asus ZenBook", company: "Asus" },
    { model: "Asus ROG", company: "Asus" },
    { model: "Dell XPS", company: "Dell" },
    { model: "Dell Inspiron", company: "Dell" },
    { model: "HP Spectre", company: "HP" },
    { model: "HP Envy", company: "HP" },
    { model: "Lenovo ThinkPad", company: "Lenovo" },
    { model: "Lenovo Yoga", company: "Lenovo" }
  ];


  const modelOption = laptopModalArray.map(({ model, company }) => ({
    label: `${model} (${company})`,
    value: model
  }));



  const companyArray = [
    "Apple",
    "Samsung",
    "OnePlus",
    "Vivo",
    "Xiaomi",
    "Oppo",
    "Google",
    "Huawei",
    "Nokia",
    "Sony",
    "LG",
    "Motorola",
    "Asus",
    "Realme",
    "Lenovo",
    "HTC",
    "Microsoft",
    "BlackBerry",
    "ZTE",
    "Honor"
  ];

  const companyOptions = companyArray.map((company) => ({
    label: company,
    value: company
  }))

  const itemFilterArray = [
    "All",
    "Display",
    "Laptop",
    "CPU",
    "Mac Mini",
    "Mobile",
    "IMac",
  ]

  const itemFilterOption = itemFilterArray.map((prop) => ({
    label: prop,
    value: prop,
  }));

  const osFilterArray = ["All", "Mac", "Windows", "Linux"];
  const osFilterOption = osFilterArray.map((prop) => ({
    label: prop,
    value: prop
  }))

  const osArray = ["Mac", "Windows", "Linux"];
  const osOption = osArray.map((prop) => ({
    label: prop,
    value: prop
  }))

  const displayArray = ["Normal", "Adjustable"];
  const displayOption = displayArray.map((prop) => ({
    label: prop,
    value: prop
  }))


  const allocationFilterArray = ["All", "Available", "Allocated"];
  const allocationFilterOptions = allocationFilterArray.map((allocation) => ({
    label: allocation,
    value: allocation
  }))

  const addressRef = useRef(null); // Ref for textarea

  useEffect(() => {
    if (addressRef.current) {
      addressRef.current.style.height = "auto"; // Reset height to auto
      addressRef.current.style.height = `${addressRef.current.scrollHeight}px`; // Set height to scrollHeight
    }
  }, [formFields]);


  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="assets_section bg-[#fff] rounded-[12px] p-3">
      <div className="assets_subsection">
        <div className="flex flex-col lg:flex-row justify-between px-3">
          {role_accesses && (
            <div>
              <button
                className="add_assets btn btn-primary my-3"
                onClick={handleAdd}
              >
                + Add Assets
              </button>
            </div>
          )}
          <div className="flex flex-wrap md:flex-row justify-start lg:justify-center items-center gap-6">
            <Select
              className="basic-single text-capitalize md:md:my-3 !min-w-36"
              classNamePrefix="select"
              placeholder="Allocation"
              name="Allocation"
              options={allocationFilterOptions}
              value={allocationFilterOptions.find(option => option.value === allocationFilter)}
              onChange={(selectedOption) => {
                const currentParams = Object.fromEntries(searchParams.entries());
                const updatedParams = {
                  ...currentParams,
                  pageno: 1,
                  allocation: selectedOption.value,
                };
                setSearchParams(new URLSearchParams(updatedParams));
                setAllocationFilter(selectedOption.value);
                setPage(1);
              }}

            />
            <Select
              className="basic-single text-capitalize md:my-3 !min-w-36"
              classNamePrefix="select"
              placeholder="Location"
              name="Location"
              options={branchFilterList}
              value={branchFilterList.find(option => option.value === locationFilter)}
              onChange={(selectedOption) => {
                const currentParams = Object.fromEntries(searchParams.entries());
                const updatedParams = {
                  ...currentParams,
                  pageno: 1,
                  location: selectedOption.value,
                };
                setSearchParams(new URLSearchParams(updatedParams));
                setLocationFilter(selectedOption.value);
                setPage(1);
              }}
            />
            <Select
              className="basic-single md:my-3 !min-w-36 assets-item-select"
              classNamePrefix="select"
              placeholder="Assets Item"
              name="Assets Item"
              options={itemFilterOption}
              value={itemFilterOption.find(option => option.value === itemFilter)}
              onChange={(selectedOption) => {
                const currentParams = Object.fromEntries(searchParams.entries());
                const updatedParams = {
                  ...currentParams,
                  pageno: 1,
                  item: selectedOption.value,
                };
                setSearchParams(new URLSearchParams(updatedParams));
                setItemFilter(selectedOption.value);
                setPage(1);
              }}
            />
            <Select
              className="basic-single md:my-3 !min-w-36"
              classNamePrefix="select"
              placeholder="OS"
              name="OS Filter"
              options={osFilterOption}
              value={osFilterOption.find(option => option.value === osFilter)}
              onChange={(selectedOption) => {
                const currentParams = Object.fromEntries(searchParams.entries());
                const updatedParams = {
                  ...currentParams,
                  pageno: 1,
                  os: selectedOption.value,
                };
                setSearchParams(new URLSearchParams(updatedParams));
                setOsFilter(selectedOption.value);
                setPage(1);
              }}
            />
          </div>
        </div>

        {assetsList.length === 0 ? (
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        ) : (
          <div className="assets__listing">
            <div className="assets__data pb-4">
              <div className="d-flex flex-column flex-md-row align-items-start justify-content-between min-h-[600px]">
                <table className="table assets__list__table table-responsive">
                  <thead className="report__header__border">
                    <tr className="border-style">
                      <th scope="col" className="font__weight header__first__child">
                        Code
                      </th>
                      <th scope="col" className="font__weight header__first__child">
                        Item
                      </th>
                      <th scope="col" className="font__weight">
                        Location
                      </th>
                      {
                        allocationFilter !== "Available" &&
                        <th scope="col" className="font__weight">
                          Assigned
                        </th>
                      }
                      <th scope="col" className="font__weight">
                        Allocation
                      </th>
                      <th scope="col" className="font__weight">
                        OS
                      </th>
                      <th scope="col" className="font__weight"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {assetsList.map((item, index) => (
                      <tr key={index} className={`${item?.is_defected === true ? "" : ""}`}> {/* Add index as key*/}
                        <td className="!text-[#f47e53] table_tr_style align-middle" >
                          {item?.code}
                        </td>
                        <td className="table_tr_style align-middle text-capitalize">
                          <span className={`${item?.is_defected === true ? "text-[#ff3131] " : ""}    flex flex-row gap-1 justify-start items-center`}>
                            {customIcons[item?.item]} {item?.item}</span>
                        </td>
                        <td className="align-middle text-capitalize">
                          {item?.branch ? item.branch.branch_name : '--'}
                        </td>
                        {
                          allocationFilter !== "Available" &&
                          <td className="align-middle">
                            {item?.user ? item.user.first_name + ' ' + item.user.last_name : '--'}
                          </td>
                        }
                        <td className="align-middle">
                          <div
                            className={`
                              ${item.allocation === "Allocated"
                                ? "text-[#6F767E] bg-[#6F767E24]"
                                : item.allocation === "Available"
                                  ? "text-[#83BF6E] bg-[#83bf6e24]" : ""
                              }
                              py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[120px] d-flex justify-center
                            `}
                          >
                            {item?.allocation}
                          </div>
                        </td>

                        <td className="align-middle">
                          {item?.OS ? item.OS : '--'}
                        </td>

                        <td className="text-center align-middle !border-b-0 d-flex justify-content-end">
                          {role_accesses && (
                            <>
                              <button
                                className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                                onClick={() => handleView(item?.id)}
                              >
                                View
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <CustomPagination
                page={page}
                pageInformation={pageInformation}
                setPage={setPage}
                totalPages={pageInformation?.last_page}
              />
            </div>
          </div>)}

        {/* Add assets */}
        <Modal
          id="assets modal"
          className="assets_modal modal_heading"
          key="project"
          title="Add Asset"
          onClose={() => {
            setListShow(false);
            setFormFields({});
          }}
          show={listShow}
        >
          <div className="modal-body-assets add_assets_form flex flex-col ">
            <div className="w-full max-h-[480px] overflow-y-auto px-4 pt-3">
              <div className="">
                <h6 className="modal_heading">Code</h6>
                <input
                  placeholder="Code"
                  className="assets_name text-capitalize "
                  type="text"
                  name="code"
                  value={formFields.code}
                  onChange={(e) => {
                    const uppercaseValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                    setFormFields({ ...formFields, code: uppercaseValue });
                  }}
                />
              </div>
              <div className="my-3">
                <h6 className="modal_heading">Item</h6>
                <Select
                  className="basic-single text-capitalize"
                  classNamePrefix="select"
                  placeholder="Select Item"
                  name="Item"
                  options={typeOptions}
                  formatOptionLabel={customFormatOptionLabel}
                  value={formFields.item ? { label: formFields.item, value: formFields.item, icon: customIcons[formFields.item] } : null}
                  onChange={(selectedOption) =>
                    setFormFields({ ...formFields, item: selectedOption.value })
                  }
                />
              </div>
              <div className="my-3">
                <h6 className="modal_heading">Location</h6>
                <Select
                  className="basic-single text-capitalize"
                  classNamePrefix="select"
                  placeholder="Select Location"
                  name="location"
                  options={branchList}
                  value={branchList.find(option => option.value === formFields.location)}
                  onChange={(selectedOption) =>
                    setFormFields({ ...formFields, location: selectedOption ? selectedOption.value : null })
                  }
                />
              </div>
              <div className="my-3">
                <h6 className="modal_heading">Assigned</h6>
                <Select
                  className="basic-single text-capitalize select-zindex"
                  classNamePrefix="select"
                  placeholder="Assigned to"
                  name="assigned"
                  options={employeeList}
                  value={employeeList.find(option => option.value === formFields.assigned_id)}
                  onChange={(selectedOption) =>
                    setFormFields({ ...formFields, assigned_id: selectedOption ? selectedOption.value : null })
                  }
                />
              </div>
              {formFields.item && (formFields.item === "Laptop") && (
                <div className="my-3">
                  <h6 className="modal_heading">Model</h6>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="model"
                    name="model"
                    options={modelOption}
                    value={formFields.model ? { label: formFields.model, value: formFields.model } : null}
                    onChange={(selectedOption) =>
                      setFormFields({ ...formFields, model: selectedOption.value })
                    }
                  />
                </div>
              )}
              {formFields.item && formFields.item === "IMac" && (
                <>
                  <div className="my-3">
                    <h6 className="modal_heading">OS</h6>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="OS"
                      name="OS"
                      options={osOption}
                      value={formFields.OS ? { label: formFields.OS, value: formFields.OS } : null}
                      onChange={(selectedOption) =>
                        setFormFields({ ...formFields, OS: selectedOption.value })
                      }
                    />
                  </div>
                  <div className="my-3">
                    <h6 className="modal_heading">Graphics card</h6>
                    <input
                      placeholder="Enter Graphics card"
                      className="assets_name "
                      type="text"
                      name="graphics card"
                      value={formFields.graphic_card}
                      onChange={(e) =>
                        setFormFields({ ...formFields, graphic_card: e.target.value })
                      }
                    />
                  </div>
                </>
              )}
              {
                formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop") && (
                  <>
                    <div className="my-3">
                      <h6 className="modal_heading">OS</h6>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="OS"
                        name="OS"
                        options={osOption}
                        value={formFields.OS ? { label: formFields.OS, value: formFields.OS } : null}
                        onChange={(selectedOption) =>
                          setFormFields({ ...formFields, OS: selectedOption.value })
                        }
                      />
                    </div>
                    <div className="my-3">
                      <h6 className="modal_heading">OS Version</h6>
                      <input
                        placeholder="Enter OS version"
                        className="assets_name "
                        type="text"
                        name="os_version"
                        value={formFields.os_version}
                        onChange={(e) =>
                          setFormFields({ ...formFields, os_version: e.target.value })
                        }
                      />
                    </div>
                    <div className="my-3">
                      <h6 className="modal_heading">MotherBoard/Processor</h6>
                      <input
                        placeholder="Enter processor"
                        className="assets_name "
                        type="text"
                        name="processor"
                        value={formFields.processor}
                        onChange={(e) =>
                          setFormFields({ ...formFields, processor: e.target.value })
                        }
                      />
                    </div>

                    <div className="my-3">
                      <h6 className="modal_heading">Graphics card</h6>
                      <input
                        placeholder="Enter Graphics card"
                        className="assets_name "
                        type="text"
                        name="graphics card"
                        value={formFields.graphic_card}
                        onChange={(e) =>
                          setFormFields({ ...formFields, graphic_card: e.target.value })
                        }
                      />
                    </div>
                  </>
                )}
              {formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop" || formFields.item === "Mac Mini" || formFields.item === "IMac") && (
                <>
                  <div className="my-3 relative">
                    <h6 className="modal_heading">Ram</h6>
                    <input
                      placeholder="Enter ram"
                      className="assets_name "
                      type="number"
                      name="ram"
                      style={{ appearance: 'textfield' }}
                      value={formFields.ram}
                      onChange={(e) =>
                        setFormFields({ ...formFields, ram: e.target.value })
                      }
                    />
                    <span style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "6px",
                      transform: "translateY(-50 %)",
                      color: "#999"
                    }}
                    >
                      GB</span>
                  </div>
                  <div className="my-3 relative">
                    <h6 className="modal_heading">Hard disk</h6>
                    <input
                      placeholder="Enter hard disk"
                      className="assets_name "
                      type="number"
                      name="hard disk"
                      value={formFields.hard_disk}
                      onChange={(e) =>
                        setFormFields({ ...formFields, hard_disk: e.target.value })
                      }
                    />
                    <span style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "6px",
                      transform: "translateY(-50 %)",
                      color: "#999"
                    }}
                    >
                      GB</span>
                  </div>
                </>
              )}

              {formFields.item && (formFields.item === "Display") && (
                <div className="my-3">
                  <h6 className="modal_heading">Display Type</h6>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Type"
                    name="Type"
                    options={displayOption}
                    value={formFields.display_type ? { label: formFields.display_type, value: formFields.display_type } : null}
                    onChange={(selectedOption) =>
                      setFormFields({ ...formFields, display_type: selectedOption.value })
                    }
                  />
                </div>
              )}


              {formFields.item && (formFields.item === "Mobile") && (
                <div className="my-3">
                  <h6 className="modal_heading">Mobile Company</h6>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Mobile Company"
                    name="Company"
                    options={companyOptions}
                    value={formFields.mobile_company ? { label: formFields.mobile_company, value: formFields.mobile_company } : null}
                    onChange={(selectedOption) =>
                      setFormFields({ ...formFields, mobile_company: selectedOption.value })
                    }
                  />
                </div>
              )}

              <div className="my-3">
                <h6 className="model_heading">Note</h6>
                <textarea
                  className="w-full border assets_note"
                  placeholder="Add note for this Asset"
                  name="note"
                  value={formFields.note}
                  ref={addressRef} // Attach ref to textarea
                  style={{ overflow: "hidden" }} // Prevent scrollbar
                  onChange={(e) =>
                    setFormFields({ ...formFields, note: e.target.value })
                  } />
              </div>

            </div>
            <div className="sticky bottom-0 bg-white pb-3 px-4 pt-1 ">
              <button
                className="assets_save_btn"
                type="button"
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      </div >
    </div >
  );
};

export default Assets;
