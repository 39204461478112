import "./App.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  ADMIN_DASHBOARD,
  CLIENT_LIST,
  DAILY_UPDATES,
  EMPLOYEE_LISTING,
  LOGIN,
  NOT_FOUND,
  PROJECT_LEAD,
  PROJECT_DETAILS,
  PROJECT_LIST,
  SETTING,
  CANDIDATE_LEAD,
  CANDIDATE_LEADS_DETAILS,
  PROJECT_LEAD_DETAILS,
  EMPLOYEE_DETAILS,
  EDIT_EMPLOYEE_DETAILS,
  ASSETS,
  ASSETS_DETAILS,
  EDIT_ASSETS_DETAILS,
  MY_TEAM,
  MY_TEAM_DETAILS,
  TASK_DETAILS,
  MY_TASK,
  PROJECT_TASK_DETAILS,
  PROJECT_TASK_REQUESTS,
  MY_TEAM_TASK_DETAILS,
  ORGANIZATION,
} from "./components/constans/Route";
import Login from "../src/components/auth/login/Login";
import RequireAuth from "./components/auth/RequireAuth.";
import AdminDashboard from "./views/admin/adminDashboard/AdminDashboard";
import NotFound from "./views/NotFound";
import { createContext, useEffect, useState } from "react";
import CheckAuth from "./components/auth/CheckAuth";
import EmployeeListing from "./views/admin/employeeList/EmployeeListing";
import secureLocalStorage from "react-secure-storage";
import Wrapper from "./components/common/Wrapper";
import ClientListing from "./views/admin/clientList/ClientListing";
import ProjectListing from "./views/admin/projectList/ProjectListing";
import Setting from "./views/admin/setting/Setting";
import DailyUpdates from "./views/admin/dailyUpdates/DailyUpdates";
import ProjectLead from "./views/admin/projectLead/ProjectLead";
import ProjectDetails from "./views/admin/ViewProject/ProjectDetails";
import CandidateLeads from "./views/admin/candidateleads/CandidateLeads";
import CandidateLeadsDetails from "./views/admin/candidateleads/CandidateLeadsDetails";
import ProjectLeadDetails from "./views/admin/projectLead/ProjectLeadDetails";
import EmployeeListDetails from "./views/admin/employeeList/EmployeeListDetails";
import EditEmployee from "./views/admin/employeeList/EditEmployee";
import Assets from "./views/admin/assetsPage/Assets";
import Assetsdetails from "./views/admin/assetsPage/AssetsDetails";
import EditAssetsDetail from "./views/admin/assetsPage/EditAssetsDetail";
import MyTeam from "./views/admin/myTeam/MyTeam";
import MyTeamDetail from "./views/admin/myTeam/MyTeamDetail";
import MyTask from "./views/admin/MyTask/MyTask";
import MyTaskDetails from "./views/admin/MyTask/MyTaskDetails";
import TaskDetail from "./views/admin/ViewProject/TaskDetails";
import TrackerModal from "./views/TrackerModal";
import { GET_MISSED_TASK_TIMER } from "./components/utils";
import { getRequest } from "./components/axiosClient";
import TasksRequest from "./views/admin/ViewProject/TasksRequest";
import MyTeamTaskDetails from "./views/admin/myTeam/MyTeamTaskDetails";
import Organization from "./views/admin/organization/Organization";

export const boundaryContext = createContext();

function App() {
  const location = useLocation();
  const [locations, setLocations] = useState();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [navVisible, showNavbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [myTeamEmployeeName, setMyTeamEmployeeName] = useState("");
  const [role_accesses, setRole_accesses] = useState([]);
  const [myTeamActiveRoute, setMyTeamActiveRoute] = useState("");
  // useEffect(() => {
  //   setLocations((location.pathname).slice(1))
  //   }, [location]);

  useEffect(() => {
    setLocations(location.pathname.slice(1));
    setRole(secureLocalStorage.getItem("role"));

    let myToken = secureLocalStorage.getItem("token");
    if (myToken === undefined || myToken === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
    setLoading(false);
  }, [location]);

  useEffect(() => {
    let myToken = secureLocalStorage.getItem("token");
    const role_accesses1 = JSON.parse(
      secureLocalStorage.getItem("role_accesses")
    );

    if (myToken === undefined || myToken === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
      setRole_accesses(role_accesses1);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();

  const [showTrackerModal, setShowTrackerModal] = useState(false);

  // Example check - Replace with actual logic
  useEffect(() => {
    // const trackerStatus = secureLocalStorage.getItem('trackerStatus');
    // const trackerDate = secureLocalStorage.getItem('trackerDate');
    // if (trackerStatus === 'on' && trackerDate !== getCurrentDate()) {
    if (loggedIn) {
      getTimeInfo();
      // setShowTrackerModal(true);
    }
    // }
  }, [loggedIn]);


  const getTimeInfo = async () => {
    try {
      let apiUrl;
      apiUrl = `${GET_MISSED_TASK_TIMER}`;
      const res = await getRequest(
        apiUrl,
        null,
        navigate
      );
      if (res.data && res.data.data) {
        const data = res.data.data;
        if (data.length > 0) {
          setShowTrackerModal(true);
        } else {
          setShowTrackerModal(false);
        }
      }
    }
    catch (error) {
      console.error("Error fetching tracker data:", error);
    }
  }

  const routesData = [
    {
      id: 1,
      to: ADMIN_DASHBOARD,
      allowedRoles: [role],
      Component: AdminDashboard,
    },
    {
      id: 2,
      to: NOT_FOUND,
      allowedRoles: [role],
      Component: NotFound,
    },
    {
      id: 3,
      to: EMPLOYEE_LISTING,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Employee List")?.access_type !== "None" && role],
      Component: EmployeeListing,
    },
    {
      id: 4,
      to: CLIENT_LIST,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Client")?.access_type !== "None" && role],
      Component: ClientListing,
    },
    {
      id: 5,
      to: DAILY_UPDATES,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Daily Updates")?.access_type !== "None" && role],
      Component: DailyUpdates,
    },
    {
      id: 6,
      to: PROJECT_LIST.split('?')[0],
      allowedRoles: [role_accesses.find((access) => access.module.name === "Projects")?.access_type !== "None" && role],
      Component: ProjectListing,
    },
    {
      id: 7,
      to: PROJECT_LEAD,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Project Lead")?.access_type !== "None" && role],
      Component: ProjectLead,
    },
    {
      id: 8,
      to: SETTING,
      allowedRoles: [role],
      Component: Setting,
    },
    {
      id: 9,
      to: PROJECT_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Projects")?.access_type !== "None" && role],
      Component: ProjectDetails,
    },
    {
      id: 11,
      to: PROJECT_LEAD_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Project Lead")?.access_type !== "None" && role],
      Component: ProjectLeadDetails,
    },
    {
      id: 12,
      to: CANDIDATE_LEAD,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Candidate Lead")?.access_type !== "None" && role],
      Component: CandidateLeads
    },
    {
      id: 13,
      to: CANDIDATE_LEADS_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Candidate Lead")?.access_type !== "None" && role],
      Component: CandidateLeadsDetails
    },
    {
      id: 14,
      to: EMPLOYEE_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Employee List")?.access_type !== "None" && role],
      Component: EmployeeListDetails
    },
    {
      id: 15,
      to: EDIT_EMPLOYEE_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Employee List")?.access_type !== "None" && role],
      Component: EditEmployee
    },
    {
      id: 10,
      to: ASSETS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Assets")?.access_type !== "None" && role],
      Component: Assets
    },
    {
      id: 17,
      to: ASSETS_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Assets")?.access_type !== "None" && role],
      Component: Assetsdetails
    },
    {
      id: 16,
      to: EDIT_ASSETS_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Assets")?.access_type !== "None" && role],
      Component: EditAssetsDetail
    },
    {
      id: 18,
      to: MY_TEAM,
      allowedRoles: [role_accesses.find((access) => access.module.name === "My Team")?.access_type !== "None" && role],
      Component: MyTeam
    },
    {
      id: 19,
      to: MY_TEAM_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "My Team")?.access_type !== "None" && role],
      Component: MyTeamDetail
    },
    {
      id: 20,
      to: TASK_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "My Task")?.access_type !== "None" && role],
      Component: MyTaskDetails
    },
    {
      id: 21,
      to: MY_TASK,
      allowedRoles: [role_accesses.find((access) => access.module.name === "My Task")?.access_type !== "None" && role],
      Component: MyTask
    },
    {
      id: 22,
      to: PROJECT_TASK_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Projects")?.access_type !== "None" && role],
      Component: TaskDetail
    },
    {
      id: 23,
      to: PROJECT_TASK_REQUESTS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Projects")?.access_type !== "None" && role],
      Component: TasksRequest
    },
    {
      id: 24,
      to: MY_TEAM_TASK_DETAILS,
      allowedRoles: [role_accesses.find((access) => access.module.name === "My Team")?.access_type !== "None" && role],
      Component: MyTeamTaskDetails
    },
    {
      id: 25,
      to: ORGANIZATION,
      allowedRoles: [role_accesses.find((access) => access.module.name === "Organization")?.access_type !== "None" && role],
      Component: Organization
    }
  ];

  return (
    <div>
      <Wrapper loading={loading}>
        <boundaryContext.Provider
          value={{
            profileImage,
            setProfileImage,
            projectName,
            setProjectName,
            employeeName,
            setEmployeeName,
            myTeamEmployeeName,
            setMyTeamEmployeeName,
            userData,
            setUserData,
            myTeamActiveRoute,
            setMyTeamActiveRoute
          }}
        >
          <Routes>
            <Route element={<CheckAuth loggedIn={loggedIn} role={role} />}>
              <Route path={LOGIN} element={<Login location={locations} />} />
            </Route>

            {routesData.map(({ allowedRoles, id, to, Component, show }) => {
              return (
                <Route
                  to={to}
                  key={id}
                  element={
                    <RequireAuth
                      show={show}
                      loggedIn={loggedIn}
                      allowedRoles={allowedRoles}
                      toggleSidebar={toggleSidebar}
                      isSidebarOpen={isSidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                    />
                  }
                >
                  <Route path="*" element={<Navigate to={NOT_FOUND} />} />
                  <Route
                    path={to}
                    element={<Component />}
                    visible={navVisible}
                    show={showNavbar}
                  />
                </Route>
              );
            })}
          </Routes>
          {showTrackerModal && <TrackerModal onClose={() => setShowTrackerModal(false)} />}

        </boundaryContext.Provider>
      </Wrapper>
    </div>
  );
}

export default App;
