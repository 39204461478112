import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import "./assets.css";
import Macmini_icon from '../../../components/assets/Mac_mini_icon.svg'
import { FaLaptop } from "react-icons/fa";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { BsCpu, BsDisplay } from "react-icons/bs";
import { getRequest, patchRequest } from '../../../components/axiosClient';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { EDIT_ASSET, GET_ASSETS_BY_ID, GET_BRANCH, GET_EMPLOYEE_LEST_FOR_ASSETS } from '../../../components/utils';
function EditAssetsDetail() {

    const [formFields, setFormFields] = useState({
        code: "",
        item: "",
        location: "",
        assigned_id: "",
        processor: "",
        graphic_card: "",
        ram: "",
        hard_disk: "",
        model: "",
        display_type: "",
        mobile_company: "",
        allocation: "",
        is_defected: "",
        OS: "",
        os_version: "",
        note: "",
    });
    const [branchList, setBranchList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        getAssetDetail();
        getAllDepartment();
        getAllEmployee();
    }, []);

    //navigate to previous page
    const goBack = () => {
        navigate(-1);
    };

    const getAssetDetail = async () => {
        try {
            const res = await getRequest(
                `${GET_ASSETS_BY_ID}?assets_id=${id}`,
                null,
                navigate
            );
            const asset = res.data.data;
            setFormFields({
                assets_id: id,
                allocation: asset?.allocation,
                code: asset?.code,
                item: asset?.item,
                location: asset?.location,
                assigned_id: asset?.assigned_id,
                processor: asset?.processor,
                os_version: asset?.os_version,
                graphic_card: asset?.graphic_card,
                OS: asset?.OS,
                ram: asset?.ram,
                hard_disk: asset?.hard_disk,
                model: asset?.model,
                display_type: asset?.display_type,
                mobile_company: asset?.mobile_company,
                is_defected: asset?.is_defected,
                note: asset?.note || "",
            });
        } catch (error) {
            console.error(error);
            errorNotification(error);
        }
    };

    const EditAssetsDetail = async (payload) => {
        try {
            const res = await patchRequest(
                `${EDIT_ASSET}`,
                payload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
                successNotification(res?.response.data.message);
                getAssetDetail();
            }
        } catch (error) {
            console.error(error);
            errorNotification(error);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormFields(formField => {
            return {
                ...formField,
                allocation: null
            }
        });

        if (formFields.item === "IMac") {
            setFormFields(formField => {
                return {
                    ...formField,
                    model: "",
                    display_type: "",
                    mobile_company: "",
                    OS: "",
                    os_version : ""
                }
            })
        }

        if (formFields.item === "Mobile") {
            setFormFields(formField => {
                return {
                    ...formField,
                    processor: "",
                    graphic_card: "",
                    ram: "",
                    hard_disk: "",
                    model: "",
                    display_type: "",
                    OS: "",
                    os_version : ""
                }
            })
        }

        if (formFields.item === "Display") {
            setFormFields(formField => {
                return {
                    ...formField,
                    processor: "",
                    graphic_card: "",
                    ram: "",
                    hard_disk: "",
                    model: "",
                    mobile_company: "",
                    OS: "",
                    os_version : ""
                }
            })
        }

        if (formFields.item === "Laptop") {
            setFormFields(formField => {
                return {
                    ...formField,
                    display_type: "",
                    mobile_company: ""
                }
            })
        }

        if (formFields.item === "Mac Mini") {
            setFormFields(formField => {
                return {
                    ...formField,
                    processor: "",
                    graphic_card: "",
                    model: "",
                    display_type: "",
                    mobile_company: "",
                    OS: "",
                    os_version : ""
                }
            })
        }

        if (formFields.item === "CPU") {
            setFormFields(formField => {
                return {
                    ...formField,
                    model: "",
                    display_type: "",
                    mobile_company: ""
                }
            })
        }

        const assetData = Object.fromEntries(
            Object.entries(formFields).map(([key, value]) => [key, value === "" ? null : value])
        );
        EditAssetsDetail(assetData);
    }

    const getAllDepartment = async () => {
        try {
            const res = await getRequest(GET_BRANCH, null, navigate);
            const dataList = res.data.data.map((item) => {
                return { value: item.id, label: item?.branch_name };
            });
            setBranchList(dataList);
        } catch (error) {
            errorNotification(error);
        }
    };

    const getAllEmployee = async () => {
        try {
            const res = await getRequest(GET_EMPLOYEE_LEST_FOR_ASSETS, null, navigate);
            const dataList = res.data.data.map((item) => {
                return { value: item.id, label: item?.first_name + " " + item?.last_name };
            });
            dataList.unshift({ value: null, label: "None" });
            setEmployeeList(dataList);
        } catch (error) {
            errorNotification(error);
        }
    };

    const setAllocation = (id) => {
        setFormFields((prevState) => ({
            ...prevState,
            allocation: id ? "Allocated" : "Available",
        }))
    }

    const customIcons = {
        CPU: <BsCpu />,
        "Mac Mini": <img src={Macmini_icon} alt="Mac mini" style={{ width: '20px', height: '20px' }} />,
        Laptop: <FaLaptop />,
        Display: <BsDisplay />,
        Mobile: <CiMobile3 />,
        IMac: <MdOutlineScreenshotMonitor />
    };

    const customFormatOptionLabel = ({ label, icon }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{label}</span>
        </div>
    );

    const typeArray = [
        "Display",
        "Laptop",
        "CPU",
        "Mac Mini",
        "Mobile",
        "IMac",
    ]

    const typeOptions = typeArray.map((prop) => ({
        label: prop,
        value: prop,
        icon: customIcons[prop]
    }));

    const displayArray = ["Normal", "Adjustable"];
    const displayOption = displayArray.map((prop) => ({
        label: prop,
        value: prop
    }))

    const statusOption = [
        {
            label: "Defected",
            value: true
        }, {
            label: "Working",
            value: false
        }]

    const osArray = ["Mac", "Windows", "Linux"];
    const osOption = osArray.map((prop) => ({
        label: prop,
        value: prop
    }))

    const laptopModalArray = [
        { model: "Macbook Air", company: "Apple" },
        { model: "Macbook Pro", company: "Apple" },
        { model: "Macbook Max", company: "Apple" },
        { model: "Asus ZenBook", company: "Asus" },
        { model: "Asus ROG", company: "Asus" },
        { model: "Dell XPS", company: "Dell" },
        { model: "Dell Inspiron", company: "Dell" },
        { model: "HP Spectre", company: "HP" },
        { model: "HP Envy", company: "HP" },
        { model: "Lenovo ThinkPad", company: "Lenovo" },
        { model: "Lenovo Yoga", company: "Lenovo" }
    ];


    const modelOption = laptopModalArray.map(({ model, company }) => ({
        label: `${model} (${company})`,
        value: model
    }));

    const companyArray = [
        "Apple",
        "Samsung",
        "OnePlus",
        "Vivo",
        "Xiaomi",
        "Oppo",
        "Google",
        "Huawei",
        "Nokia",
        "Sony",
        "LG",
        "Motorola",
        "Asus",
        "Realme",
        "Lenovo",
        "HTC",
        "Microsoft",
        "BlackBerry",
        "ZTE",
        "Honor"
    ];

    const companyOptions = companyArray.map((company) => ({
        label: company,
        value: company
    }))

    const addressRef = useRef(null); // Ref for textarea

    useEffect(() => {
        if (addressRef.current) {
            addressRef.current.style.height = "auto"; // Reset height to auto
            if (formFields.note) {
                addressRef.current.style.height = `${addressRef.current.scrollHeight}px`; // Set height based on content
            } else {
                addressRef.current.style.height = "initial"; // Reset to initial height when content is empty
            }
        }
    }, [formFields]);


    return (
        <div className='bg-white p-[20px] rounded-[12px] flex flex-col min-h-full'>
            <div className="go__back__btn flex flex-row justify-start items-center my-2 gap-1">
                <div className="">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer !w-8" />
                </div>
                <div className='text-[#000000] leading-8 font-medium text-xl flex flex-row justify-center text-center '>
                    {formFields.code}
                </div>
            </div>
            <form className="add_assets_form grid grid-cols-1 md:grid-cols-2 gap-6 mt-2 add__candidate-leads__form">
                <div>
                    <label className="mb-2 font-medium">Item</label>
                    <Select
                        className="basic-single text-capitalize"
                        classNamePrefix="select"
                        placeholder="Select Item"
                        name="Item"
                        options={typeOptions}
                        formatOptionLabel={customFormatOptionLabel}
                        value={formFields.item ? { label: formFields.item, value: formFields.item, icon: customIcons[formFields.item] } : null}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, item: selectedOption.value })
                        }
                    />
                </div>
                <div>
                    <label className="mb-2 font-medium">Location</label>
                    <Select
                        className="basic-single text-capitalize"
                        classNamePrefix="select"
                        placeholder="Select Item"
                        name="Item"
                        options={branchList}
                        value={branchList.find(option => option.value === formFields.location)}
                        onChange={(selectedOption) =>
                            setFormFields({ ...formFields, location: selectedOption ? selectedOption.value : null })
                        }
                    />
                </div>
                <div>
                    <label className="mb-2 font-medium">Assigned</label>
                    <Select
                        className="basic-single text-capitalize"
                        classNamePrefix="select"
                        placeholder="Assigned to"
                        name="assigned"
                        options={employeeList}
                        value={employeeList.find(option => option.value === formFields.assigned_id)}
                        onChange={(selectedOption) => (
                            // let flag = selectedOption.value;
                            setFormFields((prevState) => ({
                                ...prevState,
                                assigned_id: selectedOption ? selectedOption.value : null,
                            })),
                            setAllocation(selectedOption.value)
                        )}
                    />
                </div>
                <div>
                    <label className="mb-2 font-medium">Allocation</label>
                    <span className={`
                              ${formFields?.allocation === "Allocated"
                            ? "text-[#6F767E] bg-[#6F767E24]"
                            : formFields?.allocation === "Available"
                                ? "text-[#83BF6E] bg-[#83bf6e24]" : ""
                        }
                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[120px] d-flex justify-center
                            `}>{formFields.allocation}</span>
                </div>

                <div>
                    <label className="mb-2 font-medium">Status</label>
                    <Select
                        className="basic-single text-capitalize"
                        classNamePrefix="select"
                        placeholder="Assets Status"
                        name="status"
                        options={statusOption}
                        value={statusOption.find(option => option.value === formFields.is_defected)}
                        onChange={(selectedOption) => (
                            setFormFields((prevState) => ({
                                ...prevState,
                                is_defected: selectedOption ? selectedOption.value : false,
                            }))
                        )}
                    />

                </div>

                {
                    formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop") && (
                        <div>
                            <label className="mb-2 font-medium">OS</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Select OS"
                                name="OS"
                                options={osOption}
                                value={formFields.OS ? { label: formFields.OS, value: formFields.OS } : null}
                                onChange={(selectedOption) =>
                                    setFormFields({ ...formFields, OS: selectedOption.value })
                                }
                            />
                        </div>
                    )
                }

                {
                    formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop") && (
                        <div>
                            <label className="mb-2 font-medium">OS version</label>
                            <input
                                placeholder="Enter OS version"
                                className="assets_name"
                                type="text"
                                name="os_version"
                                value={formFields.os_version || ""}
                                onChange={(e) => setFormFields({ ...formFields, os_version: e.target.value })}
                            />
                        </div>
                    )
                }

                {
                    formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop" || formFields.item === "IMac") && (
                        <div>
                            <label className="mb-2 font-medium">MotherBoard/Processor</label>
                            <input
                                placeholder="Enter Processor"
                                className="assets_name"
                                type="text"
                                name="Processor"
                                value={formFields.processor || ""}
                                onChange={(e) => setFormFields({ ...formFields, processor: e.target.value })}
                            />
                        </div>
                    )
                }

                {
                    formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop" || formFields.item === "IMac") && (
                        <div>
                            <label className="mb-2 font-medium">Graphics card</label>
                            <input
                                placeholder="Enter Graphics card"
                                className="assets_name"
                                type="text"
                                name="graphic card"
                                value={formFields.graphic_card || ""}
                                onChange={(e) => setFormFields({ ...formFields, graphic_card: e.target.value })}
                            />
                        </div>
                    )
                }

                {
                    formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop" || formFields.item === "Mac Mini" || formFields.item === "IMac") && (
                        <div>
                            <label className="mb-2 font-medium">Ram</label>
                            <div className='relative'>
                                <input
                                    placeholder="Enter ram"
                                    className="assets_name"
                                    type="number"
                                    name="ram"
                                    value={formFields.ram || ""}
                                    onChange={(e) => setFormFields({ ...formFields, ram: e.target.value })}
                                />
                                <span style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "8px",
                                    transform: "translateY(-50 %)",
                                    color: "#999"
                                }}
                                >GB</span>
                            </div>
                        </div>
                    )
                }

                {
                    formFields.item && (formFields.item === "CPU" || formFields.item === "Laptop" || formFields.item === "Mac Mini" || formFields.item === "IMac") && (
                        <div>
                            <label className="mb-2 font-medium">Hard disk</label>
                            <div className='relative'>
                                <input
                                    placeholder="Enter hard disk"
                                    className="assets_name "
                                    type="number"
                                    name="hard disk"
                                    value={formFields.hard_disk || ""}
                                    onChange={(e) => setFormFields({ ...formFields, hard_disk: e.target.value })}
                                />
                                <span style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "8px",
                                    transform: "translateY(-50 %)",
                                    color: "#999"
                                }}
                                >GB</span>
                            </div>
                        </div>
                    )
                }

                {
                    formFields.item && (formFields.item === "Laptop") && (
                        <div>
                            <label className="mb-2 font-medium">Modal</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Select Modal"
                                name="modal"
                                options={modelOption}
                                value={formFields.model ? { label: formFields.model, value: formFields.model } : null}
                                onChange={(selectedOption) =>
                                    setFormFields({ ...formFields, model: selectedOption.value })
                                }
                            />
                        </div>
                    )}


                {
                    formFields.item && (formFields.item === "Display") && (
                        <div>
                            <label className="mb-2 font-medium">Type</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Select Type"
                                name="type"
                                options={displayOption}
                                value={formFields.display_type ? { label: formFields.display_type, value: formFields.display_type } : null}
                                onChange={(selectedOption) =>
                                    setFormFields({ ...formFields, display_type: selectedOption.value })
                                }
                            />
                        </div>
                    )}

                {
                    formFields.item && (formFields.item === "Mobile") && (
                        <div>
                            <label className="mb-2 font-medium">Company</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Select Company"
                                name="company"
                                options={companyOptions}
                                value={formFields.mobile_company ? { label: formFields.mobile_company, value: formFields.mobile_company } : null}
                                onChange={(selectedOption) =>
                                    setFormFields({ ...formFields, mobile_company: selectedOption.value })
                                }
                            />
                        </div>
                    )}
                {
                    formFields.item && (
                        <div>
                            <label className="mb-2 font-medium">Note</label>
                            <textarea
                                className="w-full border assets_note"
                                placeholder="Add note for this Asset"
                                name="note"
                                value={formFields.note}
                                ref={addressRef} // Attach ref to textarea
                                style={{ overflow: "hidden", resize: "none" }} // Prevent scrollbar and resizing
                                onChange={(e) => {
                                    setFormFields({ ...formFields, note: e.target.value });
                                    if (addressRef.current) {
                                        addressRef.current.style.height = "auto"; // Reset height to auto
                                        if (e.target.value) {
                                            addressRef.current.style.height = `${addressRef.current.scrollHeight}px`; // Adjust height based on content
                                        } else {
                                            addressRef.current.style.height = "initial"; // Reset to initial height when content is empty
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}

                <div className='flex items-end pb-2'>
                    <button
                        className="bg-[#f47e53] text-[#fff] w-[100%] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer"
                        onClick={(e) => handleSubmit(e)}
                    >
                        Submit
                    </button>
                </div>
            </form >
        </div >
    )
}

export default EditAssetsDetail