import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Picker from "emoji-picker-react";
import sendIcon from "../../../components/assets/message-send-icon.svg";
import FileUploadIcon from '../../../components/assets/file_upload.png'
import EmojiPeakerIcon from '../../../components/assets/emoji_peaker.png'
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Group from "../../../components/assets/Group.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import excelIcon from "../../../components/assets/Excel.jpg";
import zipIcon from "../../../components/assets/Zip.png";
import docxIcon from "../../../components/assets/Docx.png";
import csvIcon from "../../../components/assets/Csv.png";
import { RxCross2 } from "react-icons/rx";
import { errorNotification } from '../../../components/toast-notification/common-toast';
import axios from 'axios';
import { GET_PROJECT_LEAD_COMMENT, SEND_PROJECT_LEAD_COMMENT } from '../../../components/utils';
import { getRequest } from '../../../components/axiosClient';
import dayjs from 'dayjs';
import { Avatar } from '@mui/material';
import './ProjectLead.css';
import { debounce } from 'lodash';


const icons = {
    'application/msword': wordIcon,
    'application/pdf': pdfIcon,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
    'application/x-zip-compressed': zipIcon,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
    'text/csv': csvIcon,
    'word': wordIcon,
    'pdf': pdfIcon,
    'xlsx': excelIcon,
    'zip': zipIcon,
    'docx': docxIcon,
    'csv': csvIcon,
};

const ProjectLeadComment = ({ task_id }) => {
    const [message, setMessage] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [file, setFile] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [hasMoreData, setHasMoreData] = useState(true);
    const [commentsList, setCommentsList] = useState([]);
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [isCommentsFetched, setIsCommentsFetched] = useState(false); // State to track function call
    const [pageInformation, setPageInformation] = useState({});

    const pickerRef = useRef();
    const emojiButtonRef = useRef();
    const quillRef = useRef();
    const commentSectionRef = useRef();
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            getComments();
            isMounted.current = true;
        }
    }, []);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                pickerRef.current &&
                !pickerRef.current.contains(event.target) &&
                emojiButtonRef.current &&
                !emojiButtonRef.current.contains(event.target) &&
                showPicker === true
            ) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef, emojiButtonRef, showPicker]);

    const getComments = async () => {
        try {
            setLoading(true);
            const API_URL = `${GET_PROJECT_LEAD_COMMENT}?task_id=${task_id}&page=${1}&limit=${pageSize}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setCommentsList(res.data.data.page_data.slice().reverse());
                setPageInformation(res.data.data.page_information);

                setPage(2);
            } else {
                // No more data available
                // hasMoreData(false);
                errorNotification("No data found");
            }
            setLoading(false);
            setIsCommentsFetched((prev) => !prev);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (commentSectionRef.current) {
            commentSectionRef.current.scrollTop = commentSectionRef.current.scrollHeight;
        }
    }, [isCommentsFetched]);

    const fetchData = useCallback(async () => {
        if (loading || pageInformation.current_page >= pageInformation.last_page) return;

        setLoading(true);
        // Save the current scroll position before loading new data
        const commentSection = commentSectionRef.current;
        const previousScrollHeight = commentSection.scrollHeight;
        const previousScrollTop = commentSection.scrollTop;

        try {
            const API_URL = `${GET_PROJECT_LEAD_COMMENT}?task_id=${task_id}&page=${page}&limit=${pageSize}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data.slice().reverse();
                setCommentsList((preData) => [...newPageData, ...preData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);

                requestAnimationFrame(() => {
                    commentSection.scrollTop = commentSection.scrollHeight - previousScrollHeight + previousScrollTop;
                });
            }

            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false);
            }

            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }, [page, loading, hasMoreData]);

    const handleScroll = useCallback(
        debounce(() => {
            if (commentSectionRef.current.scrollTop <= 20 && !loading) {
                fetchData();
            }
        }, 300), // 300ms debounce time
        [fetchData, loading]
    );

    useEffect(() => {
        const commentSection = commentSectionRef.current;

        if (commentSection) {
            commentSection.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (commentSection) {
                commentSection.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);



    const onEmojiClick = (event) => {

        const editor = quillRef.current.getEditor();
        editor.focus();

        const selection = editor.getSelection();
        const cursorPosition = selection ? selection.index : editor.getLength();

        editor.insertText(cursorPosition, event.emoji);
    };

    const handleSendMessage = () => {

        if (file.length == 0 && message === '') {
            errorNotification('Please enter a message or select a file');
            return;
        }
        setMessage(message);
        sendMessage();
    };

    const sendMessage = async () => {

        setLoading(true);
        const formData = new FormData();
        file.forEach((file, index) => {
            formData.append(`file${index}`, file?.file);
        });
        formData.append('task_id', task_id);
        formData.append('message', message);

        try {
            const config = {
                method: "post",
                url: `${SEND_PROJECT_LEAD_COMMENT}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        getComments();
                        setFile([]);
                        setMessage('');
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }

    }

    const handleSetMessage = (content, delta, source, editor) => {
        setMessage(content);
    };

    const removeFile = (fileToRemove) => {
        setFile(prevFiles => prevFiles.filter(file => file !== fileToRemove));
        // Optionally revoke the object URL
        URL.revokeObjectURL(fileToRemove.preview);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newFiles = files.map(file => ({
            name: file.name,
            type: file.type,
            file: file,
            preview: URL.createObjectURL(file)
        }));

        newFiles.forEach((fileObj, index) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                setFile(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[prevFiles.length + index] = { ...fileObj, content };
                    return updatedFiles;
                });
            };
            reader.readAsText(fileObj.file);
        });
    };

    const handlePickerModal = () => {
        setShowPicker(val => !val);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedItems = Array.from(event.dataTransfer.files);
        const newFiles = [];

        droppedItems.forEach(file => {
            if (file.type) {  // Check if file has a valid type
                newFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    preview: URL.createObjectURL(file),
                    file: file
                });
            } else {
                errorNotification('Folders are not allowed. Please drag and drop files only.');
                return;
            }
        });

        setFile(prevFiles => [...prevFiles, ...newFiles]);

        return () => newFiles.forEach(file => URL.revokeObjectURL(file.preview));

    };

    const DocumentTypeExtractor = (url) => {
        const getFileExtension = (url) => {
            return url.split('.').pop().split(/\#|\?/)[0];
        };
        const fileExtension = getFileExtension(url);
        return (fileExtension);
    };

    return (
        <div className='comment-section flex flex-col justify-end px-2 pt-2 mb-[-2px] h-[550px] lg:h-full'
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <div ref={commentSectionRef} className='overflow-y-scroll h-auto pb-3'>
                {
                    loading &&
                    <div className="flex flex-row justify-center items-center h-[30px]">
                        loading...
                    </div>
                }
                <div className='flex flex-col gap-3'>

                    {
                        commentsList.length > 0 && commentsList.map((comment, index) => {

                            const currentDate = dayjs(comment.createdAt).format('DD-MM-YYYY');
                            const nextCommentDate = index < commentsList.length - 1 ? (dayjs(commentsList[index - 1]?.createdAt).format('DD-MM-YYYY') || null) : null;

                            {/* for continues message show */ }

                            {/* const currentUserId = comment?.user?.id;
                            const nextUserId = index < commentsList.length - 1 ? (commentsList[index - 1]?.user?.id || null) : null; */}

                            {/* Condition */ }
                            {/* {(index === 0 || nextUserId && currentUserId !== nextUserId || (nextCommentDate && currentDate !== nextCommentDate)) ?  */ }
                            return (
                                <>

                                    {(index === 0 || nextCommentDate && currentDate !== nextCommentDate) && (
                                        <div className="relative flex items-center my-2">
                                            <div className="flex-grow border-t border-gray-400"></div>
                                            <span className="mx-4 px-4 py-1 text-sm font-medium text-gray-500 border border-gray-400 rounded-full whitespace-nowrap">
                                                {currentDate}
                                            </span>
                                            <div className="flex-grow border-t border-gray-400"></div>
                                        </div>
                                    )
                                    }



                                    <div className='w-full flex flex-row gap-[10px]' key={index}>
                                        <Avatar src={comment.user.profile_image}
                                            alt='profile'
                                            className='!h-8 !w-8 rounded-full border mt-2'
                                        />
                                        <div className='w-fit flex flex-col justify-start items-start border p-3 rounded-xl'>
                                            <div className='flex flex-row justify-start items-center mb-2'>
                                                <span className='flex flex-row justify-center items-center'>
                                                    <p className='font-semibold leading-[18px]'>{comment.user.first_name} {comment.user.last_name}</p>
                                                </span>
                                                <span className='ml-3 font-medium text-[#6F767E] text-sm'>{dayjs(comment.createdAt).format('hh:mm A')}
                                                </span>
                                            </div>
                                            {
                                                comment.message && <div>
                                                    <span dangerouslySetInnerHTML={{ __html: comment.message }} />
                                                </div>
                                            }
                                            {comment.task_comm_files.length > 0 &&
                                                <div className='w-fit mt-2 flex flex-wrap gap-2'>
                                                    {comment.task_comm_files.map((docs, newIndex) => {

                                                        const type = DocumentTypeExtractor(docs.file);

                                                        return (
                                                            <>

                                                                <div className='relative  w-[290px] group' key={newIndex}>
                                                                    <div
                                                                        className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                                                    >
                                                                        <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                                            <div className="w-[40px] h-[40px]">
                                                                                <img
                                                                                    src={
                                                                                        icons[type] || docs?.file
                                                                                    }
                                                                                    alt={type}
                                                                                    className="w-[40px] h-[40px]"
                                                                                />
                                                                            </div>
                                                                            <div className="w-[190px]">
                                                                                <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                                                    {docs?.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex flex-row justify-end gap-4  items-center w-full">
                                                                            <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                                                                                <a href={docs?.file} download={docs?.name} target="_blank">
                                                                                    <img
                                                                                        src={Group}
                                                                                        className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                                                                        alt="group-icon"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>

            </div>

            <div
                className={`rounded-xl border-1 bg-white border-[#E6E6E6] sticky bottom-0 ${isDragging ? 'border-4 border-blue-500 !bg-blue-100' : ''}`}
            >
                <div className="bg-[#F4F4F4] px-2 flex justify-between items-center rounded-t-[inherit]">
                    <div id="toolbar" className='!border-none  flex justify-between items-center '>
                        <span className="toolbar flex justify-between md:justify-start">
                            <span className="ql-formats">
                                <button className="ql-bold mr-4" />
                                <button className="ql-italic mr-4" />
                                <button className="ql-underline" />
                            </span>
                            <span className="ql-formats">
                                <button className="ql-list mr-4" value="ordered" />
                                <button className="ql-list" value="bullet" />
                            </span>
                            <button className="">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    id="file-upload-input"
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-upload-input">
                                    <img src={FileUploadIcon} className='w-5 h-5 cursor-pointer' alt="File Upload Icon" />
                                </label>
                            </button>
                            <button className="ml-4">
                                <img src={EmojiPeakerIcon}
                                    onClick={handlePickerModal}
                                    ref={emojiButtonRef}
                                    className='w-5 h-5' alt="File Upload Icon" />
                            </button>
                        </span>
                    </div>
                    <button onClick={handleSendMessage} className="p-1"><img src={sendIcon} alt="sendIcon" /></button>
                </div>
                {showPicker &&
                    (
                        <div ref={pickerRef} className="z-50 absolute left-[35px] top-[-370px] bg-white border border-gray-200 rounded-lg shadow-lg p-2">
                            <Picker
                                className="!h-[350px]"
                                onEmojiClick={onEmojiClick}
                            />
                        </div>
                    )
                }
                <div className="flex items-center !bg-white space-x-3 mt-2 sticky rounded-b-xl max-h-80 overflow-y-scroll hide-scrollbar">

                    <div className="relative flex-1 bg-white">
                        <div className="input-area relative w-full bg-white rounded-xl focus:outline-none px-2">
                            <ReactQuill
                                ref={quillRef}
                                value={message}
                                onChange={handleSetMessage}
                                modules={ProjectLeadComment.modules}
                                formats={ProjectLeadComment.formats}
                                placeholder="Type a message..."
                                className="w-full custom-quill max-h-60 overflow-y-scroll hide-scrollbar bg-white"
                            />

                        </div>
                        <div className="flex flex-wrap gap-2 mt-2 max-h-32 overflow-y-scroll hide-scrollbar px-2">
                            {
                                file.map((docs, index) => {
                                    return (
                                        <div className='relative  w-[270px] group'>
                                            <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                                onClick={() => removeFile(docs)}
                                            >
                                                <RxCross2 />
                                            </div>
                                            <div
                                                className="flex flex-row justify-between items-center border p-2.5 border-gray-300 rounded-lg text-center w-[270px]"
                                            >
                                                <div className="flex justify-start gap-2 items-center">
                                                    <div className="w-[40px] h-[40px]">
                                                        <img
                                                            src={
                                                                icons[docs.type] || docs.preview
                                                            }
                                                            alt={docs?.type}
                                                            className="w-[40px] h-[40px]"
                                                        />
                                                    </div>
                                                    <div className="max-w-[220px]">
                                                        <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                            {docs?.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

ProjectLeadComment.modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {}
    }
};

ProjectLeadComment.formats = ['underline', 'bold', 'italic', 'list', 'ordered'];

export default ProjectLeadComment