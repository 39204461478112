import { useNavigate } from "react-router-dom";
import { MY_TASK } from "../components/constans/Route";
import Modal from "./admin/Modal/Modal";
import Modal_Image from "../components/assets/Tracker_Modal_Image.svg"
import { useEffect, useState } from "react";

const TrackerModal = ({ onClose }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const handleGoToMyTask = () => {
        setIsOpen(false); 
        onClose();
        navigate(MY_TASK, { state: { fromModal: true } });
    };
    
    useEffect(() => {
        setIsOpen(true);
    }, [])

    return (
        <>
            <Modal
                title="Forget to turn off the Tracker"
                onClose={onClose}
                show={isOpen}
            >
                <div className="flex flex-col justify-center items-center p-4 text-center">
                    <img src={Modal_Image} alt="Clock" className="w-28 h-28" />
                    <h2 className="font-bold text-2xl mt-3">Task time tracking is on</h2>
                    <p className="mt-2">Do you forget to turn off the time tracker
                        so request to adjust the work time tracker</p>
                    <button className="mt-3 bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer" onClick={handleGoToMyTask}>
                        Go to My Task
                    </button>
                </div>
            </Modal>

            <style>
                {`
                    .modal-content{
                        width:550px;
                        border-radius: 16px;
                    }
                `}
            </style>
        </>
    );
}

export default TrackerModal;