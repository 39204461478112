import React, { useEffect, useState } from 'react'
import high from '../../../components/assets/high_priority.svg'
import normal from '../../../components/assets/normal_priority.svg'
import low from '../../../components/assets/low_priority.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomPagination from '../../../components/common/CustomPagination'
import RequestIconActive from '../../../components/assets/request_icon_active.svg'
import clock_inActive from '../../../components/assets/clock_inActive.svg'
import start_button from '../../../components/assets/start-icon.svg'
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { getRequest } from '../../../components/axiosClient'
import { errorNotification } from '../../../components/toast-notification/common-toast'
import { GET_USER_COMPLETED_TASKS } from '../../../components/utils'
import LoadingSpinner from '../../../components/loader/LoadingSpinner'
import { FaStop } from "react-icons/fa6";
import dayjs from 'dayjs'

const MyCompleteTaskList = ({ onStart, handlePause, runningTask, handleRequestModal }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [pageInformation, setPageInformation] = useState({});
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [completeTaskList, setCompleteTaskList] = useState([]);

    const handleTaskView = (id, projectId) => {
        if (projectId) {
            navigate(`/my-task/task-details/${id}?project_id=${projectId}`);
        } else {
            navigate(`/my-task/task-details/${id}`);
        }
    };

    useEffect(() => {
        getCompleteTaskList();
    }, [page])

    const getCompleteTaskList = async () => {
        try {
            setLoading(true);
            let apiUrl = `${GET_USER_COMPLETED_TASKS}?page=${page}&limit=${pageSize}&`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setCompleteTaskList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
            } else {
                errorNotification(res.errormessage);
                setCompleteTaskList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const customIcons = {
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='py-2 px-1'>

            {completeTaskList?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div className='min-h-[500px] flex flex-col justify-between'>
                    <div className='overflow-x-auto'>
                        <table className="table max-w-full overflow-x-auto">
                            <thead className="">
                                <tr className="align-middle">
                                    <th scope="col" className="!w-[35%] !border-b-[#EFEFEF] font-medium">
                                        Name
                                    </th>
                                    <th scope="col" className=" !border-b-[#EFEFEF] font-medium">
                                        Project
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Due Date
                                    </th>
                                    {/* <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                    Status
                                </th> */}
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Priority
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Total Time
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium text-right">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    completeTaskList?.map((item, index) => {
                                        const timeDuration = dayjs.duration(item?.duration, 'seconds');
                                        const hours = String(timeDuration.hours()).padStart(2, '0');
                                        const minutes = String(timeDuration.minutes()).padStart(2, '0');
                                        const formattedTime = `${hours}:${minutes}`;
                                        return (
                                            <tr className="align-middle border-b-0 hover:bg-[#f7f7f7] hover:rounded-lg overflow-hidden cursor-pointer" onClick={() => handleTaskView(item.id, item?.project?.id)} key={index}>
                                                <td className="border-b-0 !border-b-[#EFEFEF] p-2">
                                                    <div className="overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[16rem] md:max-w-[24rem] lg:max-w-[30rem] max-lg:[36rem]">
                                                        {item.title}
                                                    </div>
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    {item?.project?.name || '--'}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    {item?.due_date ? dayjs(item.due_date).format("DD-MM-YYYY") : "--"}
                                                </td>
                                                {/* <td className="table_tr_style align-middle">
                                                <div className={`${item?.status === "In progress" ? "text-[#db763b] bg-[#eeb08c3d]" : item?.status === "Completed" ? "text-[#83BF6E] bg-[#83bf6e24]" : item?.status === "To be verified" ? "text-[#8E59FF] bg-[#8e59ff1a]" : item?.status === "Pending" ? "text-[#6F767E] bg-[#6f767e2b]" : "text-[#6F767E] bg-[#6f767e2b]"}
                                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[130px] d-flex justify-center`}>
                                                    {item?.status}
                                                </div>
                                            </td> */}
                                                <td className="table_tr_style align-middle">
                                                    {
                                                        item?.priority ?
                                                            <span className={`flex flex-row gap-1 justify-start items-center`}>
                                                                {customIcons[item?.priority]} {item?.priority}
                                                            </span>
                                                            : '--'
                                                    }
                                                </td>
                                                <td className="table_tr_style align-middle">
                                                    <div className="flex flex-row justify-start items-center gap-1">
                                                        <img src={clock_inActive} /> <span className='w-[40px]'>{formattedTime}</span>
                                                    </div>
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF] text-right">
                                                    <div className='flex flex-row items-center gap-2 justify-end'>
                                                        {
                                                            runningTask && runningTask?.task.id === item.id ?
                                                                <button className='flex flex-row items-center gap-1 border justify-start text-black rounded-md p-2 hover:!border-black w-[110px]'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handlePause()
                                                                    }}
                                                                >
                                                                    <FaStop className='h-[12px]' /> Stop
                                                                </button>
                                                                :
                                                                <button className='flex flex-row items-center gap-1 border justify-start text-black rounded-md p-2  hover:!border-black w-[110px]'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        onStart(item, e)
                                                                    }}
                                                                >
                                                                    <img className='h-[11px] w-[11px]' src={start_button} alt="start_button" /> {item?.duration != 0 ? "Continue" : "Start"}
                                                                </button>
                                                        }
                                                        <img src={RequestIconActive} alt='!!' className='h-5 w-5 cursor-pointer'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRequestModal(item);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    {
                        <CustomPagination
                            page={page}
                            pageInformation={pageInformation}
                            setPage={setPage}
                            totalPages={pageInformation?.last_page}
                        />
                    }
                </div>
            )}
        </div>
    )
}

export default MyCompleteTaskList