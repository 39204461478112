import React, { useContext, useEffect, useState } from 'react'
import Frame from "../../../components/assets/Frame.svg";
import TotalHoverS from "../../../components/assets/TotalHoverS.svg";
import RemainingH from "../../../components/assets/RemainingH.svg";
import Deadline from "../../../components/assets/Deadline.svg";
import TotalMember from "../../../components/assets/TotalMember.svg";
import Budget from "../../../components/assets/Budget.svg";
import "./Dashbord.css";
import CompanyDashboardChart from './CompanyDashboardChart';
import { getRequest } from '../../../components/axiosClient';
import { GET_PROJECT_DASHBOARD_DATA } from '../../../components/utils';
import { useNavigate } from 'react-router-dom';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import dayjs from 'dayjs';
import { boundaryContext } from '../../../App';
import secureLocalStorage from 'react-secure-storage';


const Dashbord = ({ projectId }) => {

    const [document, setDocument] = useState([]);
    const navigate = useNavigate();
    const { setProjectName } = useContext(boundaryContext);
    const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";

    // for get all Daily Updates list API Call
    const GetProjectDocument = async () => {
        try {
            const res = await getRequest(
                `${GET_PROJECT_DASHBOARD_DATA}?project_id=${projectId}`,
                null,
                navigate
            );
            if (res.type === 2) {
                errorNotification(res.errormessage);
                navigate("/404");
            } else {
                setDocument(res.data);
                setProjectName(res?.data?.data?.name);
            }
        } catch (error) {
            errorNotification(error);
        }
    };

    useEffect(() => {
        GetProjectDocument();
    }, []);

    const parseTotalHours = (totalHours) => {
        if (typeof totalHours === 'string' && totalHours === '00:00:00') {
            return 0;
        }
        return Number(totalHours) || totalHours;
    };


    const hoursFormat = (hours) => {
        if (!hours) return null;

        const [hourPart, minutePart] = hours.split(":").map(Number);
        
        if (isNaN(hourPart) || isNaN(minutePart)) return null;
        
        const totalHours = hourPart + (minutePart / 60);
        const roundedHours = Math.round(totalHours);
        
        return roundedHours;
    };

    const convertToHours = (timeStr) => {
        const [hours, minutes, seconds] = timeStr?.split(':').map(Number);
        const totalHours = hours + minutes / 60 + seconds / 3600;
        return Math.round(totalHours); // Format to 2 decimal places
    };


    const reportCountByCompany = {
        severityCount: {
            all: document?.data?.total_hours,
            remaining: document?.data?.remaining_hours ? convertToHours(document.data?.remaining_hours) : 0,
            complete: document?.data?.total_hours_spent ? convertToHours(document.data?.total_hours_spent) : 0,
        }
    }

    const reportCards = ({ image, receivedReportText, reportCount }, index) => {
        return (
            (role_accesses || receivedReportText !== "Budget") ? (
                <div className="main__report__card mt-4" key={index}>
                    <div className="reports__card row text-center d-flex align-items-center m-2 p-2">
                        <div className="col-3">
                            <img className="reports__images__style" src={image} loading='lazy' alt="card data" />
                        </div>
                        <div className="col-6 report__font">{receivedReportText}</div>
                        <div className="col-3 report__count__font">{reportCount}</div>
                    </div>
                </div>
            ) : null
        )
    };
    return (
        <div className="hunter_audit_dashboard border-redis-[12px]">
            <div className="dashboard_audit_sup_part ">
                <div className="grid grid-cols-5 gap-4 p-3">
                    <div className="col-span-5 xl:col-span-2 rec__report__main__right self-center">
                        <div className="">
                            {[
                                {
                                    image: Frame,
                                    receivedReportText: "Total Hours",
                                    reportCount: document?.data?.total_hours && parseTotalHours(document?.data?.total_hours)
                                },
                                {
                                    image: TotalHoverS,
                                    receivedReportText: "Total Hours Spent",
                                    reportCount: document?.data?.total_hours_spent && hoursFormat(document?.data?.total_hours_spent),

                                },
                                {
                                    image: RemainingH,
                                    receivedReportText: "Remaining Hours",
                                    reportCount: document?.data?.remaining_hours && hoursFormat(document?.data?.remaining_hours),

                                },
                                {
                                    image: Deadline,
                                    receivedReportText: "Deadline",
                                    reportCount: document?.data?.deadline && dayjs(document?.data?.deadline).format("DD-MM-YYYY"),

                                },
                                {
                                    image: TotalMember,
                                    receivedReportText: "Total Members",
                                    reportCount: document?.data?.total_members,

                                },
                                {
                                    image: Budget,
                                    receivedReportText: "Budget",
                                    reportCount: "$" + document?.data?.estimated_budget,

                                }
                            ].map((header, index) => reportCards(header, index))}
                        </div>
                    </div>
                    <div className="col-span-5 xl:col-span-3 mr-5 ml-5 xl:ml-0 bg-[#fff] xl:mt-4 rounded-[12px] rec__report__main__left  ">
                        <div className="chart__style my-5 ">
                            <CompanyDashboardChart
                                reportCountByCompany={reportCountByCompany}
                            />
                            <div className="flex justify-center ms-5 me-5 g-2">
                                <div className="bottom__rect rounded-[10px] bg-[#32AD60]">
                                    <h5 className="rect__data">
                                        {reportCountByCompany?.severityCount?.complete ? reportCountByCompany?.severityCount?.complete : 0}
                                    </h5>
                                </div>
                                <div className="bottom__rect rounded-[10px] mx-3 bg-[#EA3323]">
                                    <h5 className="rect__data">
                                        {reportCountByCompany?.severityCount?.remaining ? reportCountByCompany?.severityCount?.remaining : 0}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashbord