import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { getRequest } from '../../../components/axiosClient';
import { useNavigate, useParams } from 'react-router-dom';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import dayjs from "dayjs";
import clock_active from "../../../components/assets/clock_active.svg"
import clock_inActive from "../../../components/assets/clock_inActive.svg"
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
// import "./Update.css";
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { GET_MY_TEAM_DAILY_UPDATE } from '../../../components/utils';


const MyTeamDailyUpdate = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [pageInformation, setPageInformation] = useState({});
    //for context employee name
    const id = useParams();
    const isMounted = useRef(false);
    const sheetRef = useRef();

    useEffect(() => {
        if (!isMounted.current) {
            getAllDailyUpdate();
            isMounted.current = true; // Set it to true after the initial mount
        }
    }, [])

    const getAllDailyUpdate = async () => {
        try {
            setLoading(true);
            if (!(id?.id)) {
                errorNotification('User not found');
                navigate('/404');
            }
            let apiUrl = `${GET_MY_TEAM_DAILY_UPDATE}?page=${1}&limit=${pageSize}&user_id=${id?.id}`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.type === 2) {
                errorNotification(res.errormessage);
                navigate("/404");
            }
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setPage(2);
                setData(res.data.data.page_data);
            } else {
                errorNotification("No data found");
            }
            setLoading(false);
        } catch (error) {
            errorNotification(error.message);
            setLoading(false);
        }
    };

    const fetchData = useCallback(async () => {
        if (isLoading || pageInformation.current_page >= pageInformation.last_page) return;

        setIsLoading(true);
        try {
            let apiUrl = `${GET_MY_TEAM_DAILY_UPDATE}?page=${page}&limit=${pageSize}&user_id=${id?.id}`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data;
                setData((preData) => [...preData, ...newPageData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);
            }

            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false); // No more data available
            }

            setIsLoading(false);
        } catch (error) {
            errorNotification(error);
            setIsLoading(false);
        }
    }, [page, isLoading, hasMoreData]);

    useEffect(() => {
        const handleScroll = () => {
            if (sheetRef.current) {
                const { scrollTop, clientHeight, scrollHeight } = sheetRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 20) {
                    fetchData();
                }
            }
        };

        const divElement = sheetRef.current;
        if (divElement) {
            divElement.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (divElement) {
                divElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [fetchData]);

    const dataFormat = (date) => {
        const nowDate = dayjs();
        if (nowDate.diff(date, "hour") < 24) {
            return "Today";
        } else if (nowDate.diff(date, "day") === 1) {
            return "Yesterday";
        } else if (nowDate.diff(date, "day") > 1) {
            return `${dayjs(date).format("DD MMM YYYY")}`;
        }
    };

    const convertSecondsToHH = (seconds) => {
        // Calculate the hours and minutes
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        // Format the result in HH:MM format using Day.js
        const formattedTime = dayjs()
            .hour(hours)
            .minute(minutes)
            .format('HH:mm');

        return formattedTime;
    }

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='my_team_time_sheet min-h-[500px] px-3'>
            {data.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div className='h-[calc(100vh_-_280px)] overflow-y-scroll' ref={sheetRef}>
                    {
                        data.map((item, index) => {

                            let taskNumber = 1;
                            let totalSeconds = 0;

                            item?.tasks?.forEach(project => {
                                totalSeconds += parseInt(project?.duration);
                            });
                            return (
                                <div className="rounded-lg px-1 py-3" key={index}>
                                    <table className="w-full border-transparent bg-transparent rounded-lg !overflow-hidden">
                                        <thead>
                                            <tr className="bg-[#F4F4F4]">
                                                <th className="w-1/2 px-4 py-2 border-t-2 border-b-2 border-l-2 border-[#F4F4F4] text-[#6F767E] rounded-tl-lg text-base">
                                                    {dataFormat(item?.date)}
                                                </th>
                                                <th className="w-1/4 px-4 py-2 border-t-2 border-b-2 border-[#F4F4F4] text-[#6F767E] font-medium">Project Name</th>
                                                <th className="w-[10%] px-4 py-2 border-t-2 border-b-2 border-r-2 border-[#F4F4F4] rounded-tr-lg font-medium text-[#F37D53] ">
                                                    <div className="flex flex-row justify-start items-center gap-2">
                                                        <img src={clock_active} />
                                                        {convertSecondsToHH(totalSeconds)}
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-transparent" >
                                            {item?.tasks?.map((task, taskIndex) => {
                                                let isLastRow = taskIndex === item?.tasks?.length - 1;
                                                return (
                                                    <tr key={taskIndex + task?.task?.id} className="text-sm">
                                                        <td className={`px-4 py-2.5 border-b-2 border-l-2 font-medium border-[#F4F4F4] text-[#6F767E] flex flex-row justify-start  gap-2.5 ${isLastRow ? 'rounded-bl-[8px]' : ''}`}>
                                                            <span>{taskNumber++}</span> <span>{task?.task?.title}</span>
                                                        </td>
                                                        <td className="px-4 py-2.5 border-b-2 border-[#F4F4F4] text-[#6F767E]">{task?.task?.project?.name || '--'}</td>
                                                        <td className={`px-4 py-2.5 border-b-2 border-r-2 border-[#F4F4F4] text-[#6F767E] ${isLastRow ? 'rounded-br-[8px]' : ''}`}>
                                                            <div className="flex flex-row justify-start items-center gap-2">
                                                                <img src={clock_inActive} /> {convertSecondsToHH(task?.duration)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }
                    {
                        isLoading && <div>
                            <div className="flex justify-center items-center h-[100px]">
                                <div className="">
                                    <span className="text-black">Loading...</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )}
        </div>
    );
}

export default MyTeamDailyUpdate;
