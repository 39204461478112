import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../components/loader/LoadingSpinner'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import CustomPagination from '../../../components/common/CustomPagination';
import { GET_MY_TEAM_PROJECT } from '../../../components/utils';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { getRequest } from '../../../components/axiosClient';
import dayjs from 'dayjs';

function MyTeamProjects() {

    const [loading, setLoading] = useState(false);
    const [pageInformation, setPageInformation] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const pageNo = searchParams.get('pageno');
    const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);
    const pageSize = 10;
    const [projectList, setProjectList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getAllProjects();
    }, [page]);

    const id = useParams();
    const getAllProjects = async () => {
        try {
            setLoading(true);
            if (!(id?.id)) {
                errorNotification('User not found');
                navigate('/404');
            }
            let apiUrl = `${GET_MY_TEAM_PROJECT}?page=${page}&limit=${pageSize}&user_id=${id?.id}`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.type === 2) {
                errorNotification(res.errormessage);
                navigate("/404");
            }
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setProjectList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
            } else {
                errorNotification(res.errormessage);
                setProjectList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    };


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='px-4 pb-3 min-h-[500px]'>
            {projectList.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div className='flex flex-col justify-between min-h-[500px]'>

                    <table className="table myTeam_list_table table-responsive w-full">
                        <thead className="report__header__border">
                            <tr className="border-style font-medium">
                                <th scope="col" className="font__weight header__first__child w-[6%]">
                                    No
                                </th>
                                <th scope="col" className="font__weight w-[50%]">
                                    Project Name
                                </th>
                                <th scope="col" className="font__weight w-[15%]">
                                    Type
                                </th>
                                <th scope="col" className="font__weight w-[15%]">
                                    Deadline
                                </th>
                                <th scope="col" className="font__weight w-[15%]">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {projectList.map((item, index) => (
                                <tr className='font-normal' key={index}>
                                    <td className="table_tr_style align-middle" >
                                        {item?.id}
                                    </td>
                                    <td className="table_tr_style align-middle text-capitalize">
                                        {item?.name ? item?.name : '--'}
                                    </td>
                                    <td className="align-middle text-capitalize">
                                        {item?.type ? item.type : '--'}
                                    </td>
                                    <td className="align-middle">
                                        {item?.deadline ? dayjs(item.deadline).format('DD-MM-YYYY') : '--'}
                                    </td>
                                    <td>
                                        <div
                                            className={`
                                                ${item.status === "Overdue" ? "text-[#db763b] bg-[#eeb08c3d]" : item.status === "In Progress" ? "text-[#83BF6E] bg-[#83bf6e24]" : item.status === "Complete" ? "text-[#8E59FF] bg-[#8e59ff1a]" : item.status === "Cancelled" ? "text-[#ff3131] bg-[#f5727225]" : "text-[#83BF6E] bg-[#83bf6e24]"} py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[100px] d-flex justify-center
                                            `}
                                        >
                                            {item.status}
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <CustomPagination
                        page={page}
                        pageInformation={pageInformation}
                        setPage={setPage}
                        totalPages={pageInformation?.last_page}
                    />
                </div>
            )}
        </div>
    )
}

export default MyTeamProjects