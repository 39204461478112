import React, { useEffect, useState } from 'react'
import { getRequest } from '../../../components/axiosClient';
import { useNavigate } from 'react-router-dom';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { GET_PROJECT_LEAD_ACTIVITY } from '../../../components/utils';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { Avatar } from '@mui/material';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const ProjectLeadActivity = ({task_id}) => {
    const [activity, setActivity] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState();

    useEffect(() => {
        getProjectLeadActivity();
    }, [])

    const formatDate = (date) => {
        const parsedDate = dayjs(date);
        if (parsedDate.isToday()) {
            return 'Today';
        } else if (parsedDate.isYesterday()) {
            return 'Yesterday';
        } else {
            return parsedDate.format('dddd, DD MMM YYYY');
        }
    };


    const getProjectLeadActivity = async () => {
        setLoading(true);
        try {
            const res = await getRequest(
                `${GET_PROJECT_LEAD_ACTIVITY}?task_id=${task_id}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                setActivity(res.data.data);
            }
            else {
                errorNotification(res.errormessage);
                setActivity();
            }
        } catch (error) {
            console.error(error);
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='p-2 flex flex-col justify-start items-start gap-4 lg:h-[calc(100dvh_-_300px)] lg:overflow-y-scroll w-full'>
            {!loading && activity && Object.entries(activity).map(([date, tasks]) => (
                <div key={date} className='w-full'>
                    <div className='font-semibold mb-2'>{formatDate(date)}</div>
                    {
                        tasks.map((item, index) => {
                            const sentence = `<strong>${item.user.first_name} ${item.user.last_name}</strong> ${item.activity_log}`;
                            return (
                                <div className='flex flex-row justify-between items-center border-b-2 w-full py-2 gap-2' key={index}>
                                    <div className='flex justify-start items-center text-sm gap-2'>
                                        <span className='min-w-8'>
                                            <Avatar src={item.user.profile_image}
                                                alt='profile'
                                                className='!h-8 !w-8 rounded-full border'
                                            />
                                        </span>
                                        <span className='flex flex-wrap'>
                                            <span dangerouslySetInnerHTML={{ __html: sentence }} />
                                        </span>
                                    </div>
                                    <div className='mr-1 font-light text-sm min-w-[70px]'>
                                        {dayjs(item.createdAt).format('hh:mm A')}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            ))}
        </div>
    )

}
export default ProjectLeadActivity


