import React, { useEffect, useMemo, useRef, useState } from "react";
import validator from "validator";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import "./EmployeeListing.css";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import {
  ADD_EMPLOYEE,
  GET_DEPARTMENT,
  GET_BRANCH,
  GET_ROLE,
  GET_EMPLOYEES,
  GET_TECHNOLOGY,
  GET_EMPLOYEE_DROPDOWN_LIST,
} from "../../../components/utils";
import Select from "react-select";
import {
  getRequest,
  postRequest,
} from "../../../components/axiosClient";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import secureLocalStorage from "react-secure-storage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CustomPagination from "../../../components/common/CustomPagination";
import CloseIcon from "@mui/icons-material/Close";
import debounce from 'lodash.debounce';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EmployeeListing = () => {

  const [employee_id, setEmployee_id] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setEmail] = useState("");
  const [birth_date, setbirth_date] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [department_id, setdepartment_id] = useState("");
  const [branch_id, setbranch_id] = useState("");
  const [role_id, setrole_id] = useState("");
  const [technology_id, setTechnology_id] = useState(null);
  const [joining_date, setJoining_date] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [emergency_contact_name, setemergency_contact_name] = useState("");
  const [emergency_contact_no, setemergency_contact_no] = useState("");
  const [address, setaddress] = useState("");
  const [listshow, setlistShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [listallEmployee, setListAllEmployee] = useState([]);
  const [pageInformation, setPageInformation] = useState({});
  const pageSize = 10;
  const navigate = useNavigate();
  const [sortDepartment, setSortDepartment] = useState([]);
  const [sortBranch, setSortBranch] = useState([]);
  const [sortTechnology, setSortTechnology] = useState([]);
  const [reportingPersonList, setReportingPersonList] = useState([]);
  const [reporting_person_id, setReporting_person_id] = useState(null);
  const [sortRole, setSortRole] = useState([]);


  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = searchParams.get('pageno');
  const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);

  const search_params = searchParams.get('name');
  const [search, setSearch] = useState(search_params ? search_params : '');

  const role_accesses =
    JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Employee List")?.access_type !== "Read"

  const handleClose = () => {
    setlistShow(false);
    handleAdd();
  };

  // For validation and signup user
  const handleAddEmployee = (e) => {
    e.preventDefault();
    if (validator.isEmpty(first_name)) {
      errorNotification("Please Enter employee Name");
      if (validator.isEmpty(employee_id)) {
        errorNotification("Please Enter Id");
      }
    } else if (validator.isEmpty(email)) {
      errorNotification("Please Enter Email Address.");
    } else if (!validator.isEmail(email)) {
      errorNotification("Please Enter valid Email Address.");
    } else if (!validator.isDate(birth_date, new Date())) {
      errorNotification("Please Enter valid birth date Address.");
    } else if (!validator.isDate(joining_date, new Date())) {
      errorNotification("Please Enter valid joining date Address.");
    } else if (technology_id === null) {
      errorNotification("Please Select Technology of employee.");
    } else if (reporting_person_id === null) {
      errorNotification("Please Select Reporting Person.");
    }
    else {
      //Add employee API call
      let newEmployeeData = {
        employee_id: employee_id.toUpperCase(),
        first_name: first_name,
        last_name: last_name,
        department_id: department_id?.value,
        branch_id: branch_id?.value,
        role_id: role_id?.value,
        technology_id: technology_id?.value,
        reporting_person_id: reporting_person_id?.value,
        email: email?.toLowerCase(),
        password: password,
        confirmPassword: confirmPassword,
        birth_date: birth_date,
        joining_date: joining_date,
        emergency_contact_name: emergency_contact_name,
        emergency_contact_no: emergency_contact_no,
        address: address,
        contact_number: contact_number,
      };
      {
        employeeSignup(newEmployeeData);
      }
    }
  };

  useEffect(() => {
    getAllEmployee();
  }, [page, search]);

  const handleSearchChange = (event) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    const updatedParams = {
      ...currentParams,
      pageno: 1,
      name: event.target.value,
    };
    setSearchParams(new URLSearchParams(updatedParams));
    setSearch(event.target.value);
    setPage(1);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  // for new add employee API Call
  const employeeSignup = async (payload) => {
    try {
      const res = await postRequest(ADD_EMPLOYEE, payload, navigate);

      if (res.type === 1) {
        if (res.response.data.success) {
          getAllEmployee();
          successNotification("Employee Added Successfully");
          setEmployee_id("");
          setfirst_name("");
          setlast_name("");
          setEmail("");
          setbirth_date("");
          setPassword("");
          setConfirmPassword("");
          setdepartment_id("");
          setbranch_id("");
          setJoining_date("");
          setcontact_number("");
          setemergency_contact_name("");
          setemergency_contact_no("");
          setrole_id("");
          setTechnology_id("");
          setReporting_person_id(null);
          setaddress("");
          setEditedId("");
          setlistShow(false);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  // for get all employee list API Call
  const getAllEmployee = async () => {
    try {
      setLoading(true);
      let apiUrl = `${GET_EMPLOYEES}?page=${page}&limit=${pageSize}`;
      if (search && search !== '') {
        apiUrl += `&search=${search}`;
      }
      const res = await getRequest(
        apiUrl,
        null,
        navigate
      );
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setListAllEmployee(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
      } else {
        errorNotification(res.errormessage);
        setListAllEmployee([]);
      }
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  // get all department list API call
  const getAllDepartment = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_DEPARTMENT}?page=${page}&limit=${pageSize}`,
        null,
        navigate
      );
      const dataList = res.data.data.map((item) => {
        return { value: item.id, label: item?.department_name };
      });
      setSortDepartment(dataList);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  //  get all branch list API call
  const getAllBranch = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_BRANCH}?page=${page}&limit=${pageSize}`,
        null,
        navigate
      );
      const branchlist = res.data.data.map((item) => {
        return { value: item.id, label: item?.branch_name };
      });
      setSortBranch(branchlist);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  //  get all Role list API call
  const getAllRole = async () => {
    try {
      setLoading(true);
      const res = await getRequest(`${GET_ROLE}`, null, navigate);
      const rolelist = res.data.data.map((item) => {
        return { value: item.id, label: item?.name };
      });
      setSortRole(rolelist);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  //  get all Technology list API call
  const getAllTechnology = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_TECHNOLOGY}?page=${page}&limit=${pageSize}`,
        null,
        navigate
      );
      const technologylist = res.data.data.map((item) => {
        return { value: item.id, label: item?.name };
      });
      setSortTechnology([{ value: null, label: "None" }, ...technologylist]);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  const getAllReportingPerson = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_EMPLOYEE_DROPDOWN_LIST}`,
        null,
        navigate
      );
      const list = res.data.data.map((item) => {
        let name = item.first_name + " " + item.last_name;
        return { value: item.id, label: name };
      });
      setReportingPersonList([{ value: null, label: "None" }, ...list]);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role_accesses) {
      getAllDepartment();
      getAllBranch();
      getAllRole();
      getAllTechnology();
      getAllReportingPerson();
    }
  }, []);

  //handle add btn click
  const handleAdd = (e) => {
    setEmployee_id("");
    setfirst_name("");
    setlast_name("");
    setEmail("");
    setbirth_date("");
    setPassword("");
    setdepartment_id("");
    setbranch_id("");
    setJoining_date("");
    setcontact_number("");
    setemergency_contact_name("");
    setemergency_contact_no("");
    setrole_id("");
    setTechnology_id("");
    setReporting_person_id("");
    setaddress("");
    setEditedId("");
  };

  const today = new Date().toISOString().split("T")[0];
  const handleView = (item) => {
    navigate(`/employee-list/employee-details/${item.id}`);
  }
  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="employee__section bg-[#fff] rounded-[12px]">
      <div className="employee__subsection">
        <div className="w-full flex flex-row justify-between items-center">
          {role_accesses && (
            <button
              className="add__employee mx-3 mt-3"
              onClick={(e) => setlistShow(true)}
              disabled={!role_accesses}
            >
              + Add Employee
            </button>
          )}
          <div className="!justify-self-end mx-3 mt-3">
            <input
              type="search"
              className="project_search"
              autoFocus={inputFocus ? true : false}
              defaultValue={search}
              onChange={(e) => {
                setInputFocus(true);
                debouncedResults(e);
              }}
              placeholder="Search Employees"
              ref={inputRef}
            />
          </div>
        </div>

        {listallEmployee?.length === 0 ? (
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        ) : (
          <div className="employee__listing">
            <div className="employee__data pb-2">
              <div className="d-flex align-items-start justify-content-between employee__header min-h-[600px]">
                <table className="table employee__list__table">
                  <thead className="report__header__border">
                    <tr className="border-style">
                      <th
                        scope="col"
                        className="font__weight header__first__child"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="font__weight header__first__child"
                      >
                        Full Name
                      </th>
                      <th scope="col" className="font__weight">
                        Department
                      </th>
                      <th scope="col" className="font__weight">
                        Branch
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listallEmployee?.map((item, index) => (
                      <tr key={index}>
                        <td className="table_tr_style">
                          {item.employee_id || "--"}
                        </td>
                        <td className="table_tr_style text-capitalize ">
                          {`${item.first_name || "--"} ${item.last_name || "--"
                            }`.trim()}
                        </td>
                        <td className="table_tr_style">
                          {item?.department?.department_name || "--"}
                        </td>
                        <td className="table_tr_style">
                          {item?.branch?.branch_name || "--"}
                        </td>

                        <td
                          className="text-center align-middle 
                          justify-content-end"
                        >
                          {role_accesses && (
                            <button
                              className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                              onClick={() => handleView(item)}
                              disabled={!role_accesses}
                            >
                              View
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {
                <CustomPagination
                  page={page}
                  pageInformation={pageInformation}
                  setPage={setPage}
                  totalPages={pageInformation?.last_page}
                />
              }
            </div>
          </div>
        )}

        <React.Fragment>
          <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={listshow}
            maxWidth="md"
            sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Add Employee
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <div className="container">
                <div className="row">
                  <div
                    className="col-6"
                    style={{ borderRight: "1.4px solid #EFEFEF" }}
                  >
                    <h6 className="modal-heading">Employee ID</h6>
                    <input
                      placeholder="Employee ID"
                      className="form-control employee-form-control"
                      type="text"
                      value={employee_id.toUpperCase()}
                      onChange={(e) => {
                        setEmployee_id(e.target.value);
                      }}
                    />
                    <div className="row">
                      <div className="col-6">
                        <h6 className="modal-heading">First Name</h6>
                        <input
                          placeholder="Enter First Name"
                          className="form-control employee-form-control"
                          type="text"
                          value={first_name}
                          autoComplete="off"
                          onChange={(e) => {
                            setfirst_name(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <h6 className="modal-heading">Last Name</h6>
                        <input
                          placeholder="Enter Last Name"
                          className="form-control employee-form-control"
                          type="text"
                          value={last_name}
                          autoComplete="off"
                          onChange={(e) => {
                            setlast_name(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <h6 className="modal-heading">Email</h6>
                    <input
                      placeholder="Enter Email Address"
                      type={email ? "email" : "text"}
                      className="form-control employee-form-control"
                      value={email.toLowerCase()}
                      autoComplete="off"
                      disabled={editedId ? true : false}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />

                    <div className="row">
                      <div className="col-6">
                        <h6 className="modal-heading">New Password</h6>
                        <input
                          placeholder="Enter Password"
                          className="form-control employee-form-control"
                          type="text"
                          value={password}
                          autoComplete="off"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <h6 className="modal-heading">Confirm password</h6>
                        <input
                          placeholder="Enter Password"
                          className="form-control employee-form-control"
                          type="text"
                          value={confirmPassword}
                          autoComplete="off"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <h6 className="modal-heading">Contact No.</h6>
                        <input
                          placeholder="Enter Number"
                          className="form-control employee-form-control"
                          type="text"
                          maxLength={10}
                          value={contact_number}
                          autoComplete="off"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              setcontact_number(value);
                            }
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <h6 className="modal-heading">Birthdate</h6>
                        <input
                          placeholder="date"
                          className="form-control employee-form-control"
                          type="date"
                          value={birth_date}
                          max={today}
                          autoComplete="off"
                          onChange={(e) => {
                            setbirth_date(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="row"> */}
                    <div className="col-12">
                      <h6 className="modal-heading pb-">
                        Emergency Contact Name
                      </h6>
                      <input
                        placeholder="Father Name"
                        className="form-control employee-form-control"
                        type="text"
                        value={emergency_contact_name}
                        autoComplete="off"
                        onChange={(e) => {
                          setemergency_contact_name(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <h6 className="modal-heading pb-1">Emergency No.</h6>
                      <input
                        placeholder="Enter Number"
                        className="form-control employee-form-control"
                        type="text"
                        maxLength={10}
                        value={emergency_contact_no}
                        autoComplete="off"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setemergency_contact_no(value);
                          }
                        }}
                      />
                    </div>
                    {/* </div> */}
                  </div>

                  <div className="col-6">
                    <div className="row">
                      <div className="col-6" style={{ marginBottom: "10px" }}>
                        <h6 className="modal-heading">Department</h6>
                        <Select
                          options={sortDepartment}
                          className="text-capitalize"
                          classNamePrefix="select"
                          value={department_id}
                          placeholder="Select Department"
                          name="filterhighlow"
                          isDisabled={
                            role_id?.label === "CTO" || role_id?.label === "CEO"
                              ? true
                              : false
                          }
                          onChange={(selectedOption) => {
                            setdepartment_id(selectedOption);
                          }}
                        />
                      </div>
                      <div className="col-6" style={{ marginBottom: "10px" }}>
                        <h6 className="modal-heading">Branch</h6>{" "}
                        <Select
                          options={sortBranch}
                          className=" text-capitalize"
                          classNamePrefix="select"
                          value={branch_id}
                          placeholder="Select Branch"
                          name="filterhighlow"
                          onChange={(selectedOption) =>
                            setbranch_id(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12" style={{ marginBottom: "10px" }}>
                      <h6 className="modal-heading">Employee Role</h6>
                      <Select
                        options={sortRole}
                        className="basic-single"
                        classNamePrefix="select"
                        value={role_id}
                        placeholder="Select Role"
                        name="filterhighlow"
                        isDisabled={
                          role_id?.label === "CTO" || role_id?.label === "CEO"
                            ? true
                            : false
                        }
                        onChange={(selectedOption) =>
                          setrole_id(selectedOption)
                        }
                      />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h6 className="modal-heading">Joining Date</h6>
                        <input
                          className="form-control employee-form-control"
                          type="date"
                          value={joining_date}
                          autoComplete="off"
                          onChange={(e) => {
                            setJoining_date(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-12" style={{ marginBottom: "10px" }}>
                        <h6 className="modal-heading "> Technology</h6>
                        <Select
                          options={sortTechnology}
                          className="basic-single Employ_Technology"
                          classNamePrefix="select"
                          value={technology_id}
                          placeholder="Select Technology"
                          name="filterhighlow"
                          onChange={(selectedOption) =>
                            setTechnology_id(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12" style={{ marginBottom: "10px" }}>
                        <h6 className="modal-heading ">Report to</h6>
                        <Select
                          options={reportingPersonList}
                          className="basic-single Employ_Technology"
                          classNamePrefix="select"
                          value={reporting_person_id}
                          placeholder="Select Reporting person"
                          name="filterhighlow"
                          onChange={(selectedOption) =>
                            setReporting_person_id(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <h6 className="modal-heading">Address</h6>
                    <textarea
                      placeholder="Enter Address"
                      className="form-control employee-form-control"
                      type="text"
                      value={address}
                      autoComplete="off"
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      className="emp__save__btn"
                      onClick={handleAddEmployee}
                    >
                      {editedId ? "Update" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </BootstrapDialog>
        </React.Fragment>
      </div>
    </div>
  );
};

export default EmployeeListing;