import React, { useDeferredValue, useEffect, useRef, useState } from 'react'
import Comments from '../ViewProject/Comments';
import TimeLine from '../ViewProject/TimeLine';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import Select from "react-select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Group from "../../../components/assets/Group.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import excelIcon from "../../../components/assets/Excel.jpg";
import zipIcon from "../../../components/assets/Zip.png";
import docxIcon from "../../../components/assets/Docx.png";
import csvIcon from "../../../components/assets/Csv.png";
import Upload_Icon from '../../../components/assets/Upload-icon.svg'
import fileIcon from "../../../components/assets/file-alt-solid.svg"
import high from '../../../components/assets/high_priority.svg'
import normal from '../../../components/assets/normal_priority.svg'
import low from '../../../components/assets/low_priority.svg'
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { deleteRequest, getRequest, patchRequest } from '../../../components/axiosClient';
import { debounce } from 'lodash';
import { DELETE_TASK_DOCUMENT, GET_PROJECT_MEMBERS, GET_TASK_BY_ID, UPDATE_TASK, UPLOAD_TASK_DOCUMENT } from '../../../components/utils';
import validator from 'validator';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Activity from './Activity';

function TaskDetails() {

    const role_access = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";
    const icons = {
        'application/msword': wordIcon,
        'application/pdf': pdfIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
        'application/x-zip-compressed': zipIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
        'text/csv': csvIcon,
        'word': wordIcon,
        'pdf': pdfIcon,
        'xlsx': excelIcon,
        'zip': zipIcon,
        'docx': docxIcon,
        'csv': csvIcon,
    };

    const [activeTab, setActiveTab] = useState("comments");
    const [isDragging, setIsDragging] = useState(false);
    const [memberList, setMemberList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const location = useLocation();
    const hasMounted = useRef(false);
    const titleRef = useRef();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get('project_id');
    const [taskDetails, setTaskDetails] = useState({
        task_id: id
    });
    const [updatedTaskDetails, setUpdatedTaskDetails] = useState({
        task_id: id,
    });

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        getTaskDetails();
        getProjectMembers();
    }, [])

    const debouncedUpdateTask = debounce((prop) => {
        // Your API call logic here, for example:
        updateTaskDetails(prop);
    }, 1000);

    const previousTaskDetails = useRef(taskDetails);
    const previousUpdatedTaskDetails = useRef(updatedTaskDetails);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            return;
        }

        // Check if both taskDetails and updatedTaskDetails have changed
        const taskDetailsChanged = previousTaskDetails.current !== taskDetails;
        const updatedTaskDetailsChanged = previousUpdatedTaskDetails.current !== updatedTaskDetails;

        if (taskDetailsChanged && updatedTaskDetailsChanged) {
            debouncedUpdateTask(updatedTaskDetails);
        }

        // Update refs to current values
        previousTaskDetails.current = taskDetails;
        previousUpdatedTaskDetails.current = updatedTaskDetails;

        return () => {
            debouncedUpdateTask.cancel();
        };
    }, [taskDetails, updatedTaskDetails]);

    const updateTaskDetails = async (payload) => {

        //Don't make API call when still focus is true
        if (titleRef.current && document.activeElement === titleRef.current) {
            return;
        } else {
            previousTaskDetails.current = taskDetails;
            previousUpdatedTaskDetails.current = updatedTaskDetails;
        }
        
        if (payload?.title !== undefined && validator.isEmpty(payload.title)) {
            getTaskDetails();
            setUpdatedTaskDetails({
                task_id: id
            })
            errorNotification("Task title can not be empty");
            return;
        }
        const updatedPayload = { ...payload };

        if (payload?.due_date !== undefined) {
            updatedPayload.due_date = typeof payload.due_date === 'string' && payload.due_date.trim() === ""
                ? null
                : payload.due_date;
        }

        try {
            const res = await patchRequest(
                `${UPDATE_TASK}`,
                updatedPayload,
                navigate
            );
            if (res?.response && res?.response.data.success) {
            } else {
                getTaskDetails();
                errorNotification(res?.errormessage);
            }
            setUpdatedTaskDetails({
                task_id: id
            })
        } catch (error) {
            getTaskDetails();
            console.error(error);
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    };

    const getTaskDetails = async () => {
        setLoading(true);
        try {
            const res = await getRequest(
                `${GET_TASK_BY_ID}?task_id=${id}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                setTaskDetails((prevTaskDetails) => ({
                    ...prevTaskDetails,
                    ...res.data.data
                }));
                setUpdatedTaskDetails({
                    task_id: id
                })
                setFileList(res.data.data.task_documents);
            }
            else {
                errorNotification(res.errormessage);
                setTaskDetails();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
            errorNotification(error);
        }
    }

    const handleTabChange = (e) => {
        setActiveTab(e.target.name);
    };

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const getProjectMembers = async () => {
        try {
            setLoading(true);
            const res = await getRequest(
                `${GET_PROJECT_MEMBERS}?project_id=${projectId}`,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                const dataList = res.data.data.map((item) => {
                    return { value: item?.user.id, label: item?.user.first_name + ' ' + item?.user.last_name };
                });
                dataList.unshift({ value: null, label: "None" });
                setMemberList(dataList);
            } else {
                errorNotification(res.errormessage);
                setMemberList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleFileUpload = async (e) => {
        const selectedFile = Array.from(e.target.files);
        handleFileOperation(selectedFile);
    }


    const handleFileOperation = async (file) => {
        if (!file || file.length > 1) {
            errorNotification('You can only upload one file at a time.');
            return;
        }

        const singleFile = file[0];
        if (!singleFile.type) {
            errorNotification('Folders are not allowed. Please drag and drop files only.');
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('document', singleFile);
        formData.append('task_id', id);
        try {
            const config = {
                method: "post",
                url: `${UPLOAD_TASK_DOCUMENT}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        getTaskDetails();
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedItems = Array.from(event.dataTransfer.files);
        handleFileOperation(droppedItems);
    };

    const removeFile = async (id) => {
        setLoading(true);
        try {
            const res = await deleteRequest(`${DELETE_TASK_DOCUMENT}?document_id=${id}`, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    getTaskDetails();
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        } finally {
            setLoading(false);
        }
    };

    const [value, setValue] = useState('');
    const [showInput, setShowInput] = useState(false);

    const handleAddTag = () => {
        setShowInput(true);
        setValue('');
    }

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleBlur = () => {
        if (value.length > 2) {
            submitValue();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents default behavior (like form submission)
            if (value.length > 2) {
                submitValue();
            }
        }
    };

    const submitValue = () => {

        // Create a new array with the current tags plus the new value
        let updatedArray = [...(taskDetails.tags || []), value];


        // Update both states with the new array
        setUpdatedTaskDetails(prevState => ({
            ...prevState,
            tags: updatedArray
        }));

        setTaskDetails(prevState => ({
            ...prevState,
            tags: updatedArray
        }));
        setShowInput(false);
        // Clear the input value
        setValue('');
    };

    const handleTagRemove = (index) => {
        let array = [...taskDetails.tags];
        array.splice(index, 1);
        setTaskDetails({ ...taskDetails, tags: array });
        setUpdatedTaskDetails({ ...updatedTaskDetails, tags: array });
    }

    const customFormatOptionLabel = ({ label, icon }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{label}</span>
        </div>
    );

    const DocumentTypeExtractor = (url) => {
        const getFileExtension = (url) => {
            return url.split('.').pop().split(/\#|\?/)[0];
        };
        const fileExtension = getFileExtension(url);
        return (fileExtension);
    };

    const customIcons = {
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
    };

    const priorityOptionsList = ["Low", "Medium", "High"];
    const priorityOption = priorityOptionsList.map((props) => ({
        label: props,
        value: props,
        icon: customIcons[props]
    }));

    const statusOptionList = ["To do", "In progress", "To be verified", "Completed"];
    const statusOption = statusOptionList.map((props) => ({
        label: props,
        value: props
    }));

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className={`p-4 bg-white rounded-lg flex flex-col lg:h-[calc(100dvh_-_165px)] h-full`}>
            <div className={`go__back__btn flex flex-row gap-3 items-center cursor-pointer mb-2`}>
                <ArrowBackIcon onClick={goBack} loading="lazy" />
            </div>
            <div className='flex flex-col lg:flex-row h-full pb-2 '>
                <div className={`${isDragging ? 'border-4 border-blue-500 bg-blue-100' : ''} pr-3 lg:border-r-2 w-full lg:w-[40%] overflow-y-scroll hide-scrollbar`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className='border-[#cfcbcb] w-full'>
                        <input
                            type='text'
                            ref={titleRef}
                            placeholder='Enter title of task'
                            className={`focus:border-2 border-[#cfcbcb] w-full rounded-md px-2 py-2 font-medium text-xl`}
                            value={taskDetails.title}
                            onChange={(e) => {
                                setTaskDetails({ ...taskDetails, title: e.target.value })
                                setUpdatedTaskDetails({
                                    ...updatedTaskDetails,
                                    title: e.target.value
                                })
                            }}
                            onBlur={() => {
                                updateTaskDetails(updatedTaskDetails);
                            }}
                        // readOnly={!role_access}
                        />
                    </div>
                    <div className='border-b-2 mb-3'></div>
                    <div className='flex flex-col gap-3'>
                        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center">
                            <p className="text-[#000000] font-medium min-w-[110px]">Assignee</p>
                            <Select className="rounded-lg text-capitalize min-w-40"
                                options={memberList}
                                name="assignee to"
                                value={memberList.find(option => option.value === taskDetails.assigned_to)}
                                onChange={(selectedOption) => {
                                    setTaskDetails({ ...taskDetails, assigned_to: selectedOption ? selectedOption.value : null });
                                    setUpdatedTaskDetails({
                                        ...updatedTaskDetails,
                                        assigned_to: selectedOption ? selectedOption.value : null
                                    });

                                }}
                            // isDisabled={!role_access}

                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="text-[#000000] font-medium min-w-[110px]">Date</span>
                            <input
                                placeholder="Enter due date"
                                className="p-2 h-[40px] w-fit rounded-lg  border-1 border-[#cfcbcb]"
                                type="date"
                                name="os_version"
                                value={taskDetails.due_date}
                                onChange={(e) => {
                                    setTaskDetails({
                                        ...taskDetails,
                                        due_date: e.target.value,
                                    });
                                    setUpdatedTaskDetails({
                                        ...updatedTaskDetails,
                                        due_date: e.target.value,
                                    })
                                }}
                            // readOnly={!role_access}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center">
                            <p className="text-[#000000] font-medium min-w-[110px]">Project</p>
                            <input
                                placeholder="Enter OS version"
                                className="p-2 h-[40px] w-fit rounded-lg  border-1 border-[#cfcbcb]"
                                type="text"
                                name="os_version"
                                value={taskDetails?.project?.name}
                                readOnly
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[110px] ">Status</span>
                            <Select
                                className="basic-single text-capitalize min-w-40 border-1"
                                classNamePrefix="select"
                                placeholder="Status"
                                name="selectedClient"
                                options={statusOption}
                                value={taskDetails.status ?
                                    { label: taskDetails.status, value: taskDetails.status } : null}
                                onChange={(e) => {
                                    setTaskDetails({
                                        ...taskDetails,
                                        status: e.value,
                                    });
                                    setUpdatedTaskDetails({
                                        ...updatedTaskDetails,
                                        status: e.value,
                                    });
                                }}
                            // isDisabled={!role_access}

                            />
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[110px]">Priority</span>
                            <Select
                                className="basic-single text-capitalize min-w-40 border-1"
                                classNamePrefix="select"
                                formatOptionLabel={customFormatOptionLabel}
                                placeholder="Priority"
                                name="selectedClient"
                                options={priorityOption}
                                value={taskDetails.priority ?
                                    { label: taskDetails.priority, value: taskDetails.priority, icon: customIcons[taskDetails.priority] } : null}
                                onChange={(e) => {
                                    setTaskDetails({
                                        ...taskDetails,
                                        priority: e.value,
                                    });
                                    setUpdatedTaskDetails({
                                        ...updatedTaskDetails,
                                        priority: e.value,
                                    });
                                }}
                            // isDisabled={!role_access}
                            />

                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
                            <span className="text-[#000000] font-medium mt-[12px] min-w-[110px]">Tag</span>
                            <div className="mt-2 flex flex-wrap gap-2">

                                {
                                    taskDetails.tags &&
                                    taskDetails.tags.map((tags, index) => (
                                        <span className="flex justify-center items-center bg-green-200 text-green-700 px-2 py-1 rounded" key={index}>
                                            {tags}
                                            <span className="ml-1 cursor-pointer" onClick={(e) => handleTagRemove(index)}>
                                                <RxCross2 />
                                            </span>
                                        </span>
                                    ))
                                }
                                {
                                    showInput ?
                                        <input
                                            className='px-2 py-1 min-w-[40px] w-auto border max-w-[100px]'
                                            value={value}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            autoFocus
                                        />

                                        : <button className='cursor-pointer h-8 w-8 rounded border'
                                            onClick={() => handleAddTag()}
                                        >
                                            +</button>
                                }
                            </div>
                        </div>

                        <div className="pt-2 pb-1 rounded-lg">
                            <div className='rounded-lg'>
                                <h6 className='mb-2 text-[#000000] font-medium min-w-[110px]'>Description</h6>
                                <textarea
                                    type="text"
                                    name="description"
                                    className='min-h-10 h-32 w-full rounded-lg border hover:border-black p-[6px] bg-transparent'
                                    value={taskDetails.description}
                                    onChange={(e) => {
                                        setTaskDetails({
                                            ...taskDetails,
                                            description: e.target.value,
                                        });
                                        setUpdatedTaskDetails({
                                            ...updatedTaskDetails,
                                            description: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                            <div className=' flex flex-col justify-start gap-2'>
                                {/* Tab navigation and content */}
                                <h6 className='text-[#000000] font-medium mt-3 min-w-[110px]'>Documents</h6>
                                <div className="">
                                    <div className='flex flex-wrap gap-2 mt-2'>
                                        <div className={`relative flex flex-row justify-center items-center h-[62px] w-[290px] rounded-xl border-2 border-dashed bg-[#F8F8F8] `}>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"
                                                multiple
                                                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                                onChange={(e) => handleFileUpload(e)}
                                            />
                                            <img src={Upload_Icon} className="h-3.5 mr-2" alt="Upload" />
                                            <p>Upload File</p>
                                        </div>
                                        {fileList.map((docs, index) => {

                                            const type = DocumentTypeExtractor(docs.document);

                                            return (
                                                <div className='relative  w-[290px] group'>
                                                    <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                                        onClick={() => removeFile(docs?.id)}
                                                    >
                                                        <RxCross2 />
                                                    </div>
                                                    <div
                                                        className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                                    >
                                                        <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                            <div className="w-[40px] h-[40px]">
                                                                <img
                                                                    src={
                                                                        icons[type] || docs?.document
                                                                    }
                                                                    alt={type}
                                                                    className="w-[40px] h-[40px]"
                                                                />
                                                            </div>
                                                            <div className="w-[190px]">
                                                                <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                                    {docs?.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row justify-end gap-4  items-center w-full">
                                                            <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                                                                <a href={docs?.document} download target="_blank">
                                                                    <img
                                                                        src={Group}
                                                                        className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                                                        alt="group-icon"
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:pl-3 w-full lg:w-[60%]'>
                    <div className='flex flex-col justify-start gap-4'>
                        <ul className="nav nav-pills mb-3 mt-2" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "comments" ? "active" : ""}`}
                                    id="pills-comments-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-comments"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-comments"
                                    aria-selected="true"
                                    onClick={(e) => handleTabChange(e)}
                                    name="comments"
                                >
                                    Comments
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "time-line" ? "active" : ""}`}
                                    id="pills-time-line-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-time-line"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-time-line"
                                    aria-selected="false"
                                    name="time-line"
                                    onClick={(e) => handleTabChange(e)}
                                >
                                    Time Line
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link font-medium ${activeTab === "activity" ? "active" : ""}`}
                                    id="pills-activity-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-activity"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-activity"
                                    aria-selected="false"
                                    name="activity"
                                    onClick={(e) => handleTabChange(e)}
                                >
                                    Activity
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="program_scroll h-[calc(100%_-_70px)]">
                        <div className="tab-content h-full" id="pills-tabContent">
                            <div
                                className={`h-full tab-pane fade ${activeTab === "comments" ? "show active" : ""
                                    }`}
                                id="pills-comments"
                                role="tabpanel"
                                aria-labelledby="pills-comments-tab"
                            >
                                {activeTab === "comments" && <Comments task_id={id} call_id={1}/>}
                            </div>
                            <div
                                className={`tab-pane fade h-full ${activeTab === "time-line" ? "show active" : ""
                                    }`}
                                id="pills-time-line"
                                role="tabpanel"
                                aria-labelledby="pills-time-line-tab"
                            >
                                {activeTab === "time-line" && <TimeLine task_id={id} call_id={1}/>}
                            </div>
                            <div
                                className={`tab-pane fade h-full ${activeTab === "activity" ? "show active" : ""
                                    }`}
                                id="pills-activity"
                                role="tabpanel"
                                aria-labelledby="pills-activity-tab"
                            >
                                {activeTab === "activity" && <Activity task_id={id} call_id={1}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>
                {`
                    .hide-scrollbar::-webkit-scrollbar {
                        display: none;
                    }

                /* Hide scrollbar for Firefox */
                    .hide-scrollbar {
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */
                    }
                `}
            </style>
        </div>
    )
}

export default TaskDetails
