import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import "./CandidateLeads.css";
import {
    errorNotification,
    successNotification,
} from "../../../components/toast-notification/common-toast";
import {
    ADD_CANDIDATE_LEAD,
    GET_CANDIDATE_LEAD
} from "../../../components/utils";
import {
    getRequest
} from "../../../components/axiosClient";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import CustomPagination from "../../../components/common/CustomPagination";
import secureLocalStorage from "react-secure-storage";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import dayjs from "dayjs";
import axios from "axios";
import validator from "validator";
import { RxCross2 } from "react-icons/rx";

import wordIcon from "../../../components/assets/wordicon.svg";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import excelIcon from "../../../components/assets/Excel.jpg";
import zipIcon from "../../../components/assets/Zip.png";
import docxIcon from "../../../components/assets/Docx.png";
import csvIcon from "../../../components/assets/Csv.png";


const defaultFormData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_info: "",
    applied_position: "",
    experience_year: "",
    interview_date: "",     // YYYY-MM-DD
    interview_time: "",     // HH:MM 
    status: "Open",
    stage: "",
    lead_id: "",
};

const CandidateLeads = () => {


    const icons = {
        'application/msword': wordIcon,
        'application/pdf': pdfIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excelIcon,
        'application/x-zip-compressed': zipIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docxIcon,
        'text/csv': csvIcon,
        'word': wordIcon,
        'pdf': pdfIcon,
        'xlsx': excelIcon,
        'zip': zipIcon,
        'docx': docxIcon,
        'csv': csvIcon,
    };


    const [pageInformation, setPageInformation] = useState({});
    const pageSize = 10;
    const navigate = useNavigate();
    const role_accesses =
        JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Candidate Lead")?.access_type !== "Read";

    const [loading, setLoading] = useState(false);
    const [listShow, setListShow] = useState(false);
    const [allCandidate, setAllCandidate] = useState([]);

    const [appliedPosition, setAppliedPosition] = useState(null);
    const [experience, setExperience] = useState(null);
    const [interviewDate, setInterviewDate] = useState("");
    const [interviewTime, setInterviewTime] = useState("");

    const [formData, setFormData] = useState(defaultFormData);
    const [resumeFile, setResumeFile] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const pageNo = searchParams.get('pageno');
    const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);

    const filterParams = searchParams.get('stage');
    const [filter, setFilter] = useState(filterParams ? {
        label: filterParams,
        value: filterParams
    } : null);

    const statusParams = searchParams.get('status');
    const [statusFilter, setStatusFilter] = useState(statusParams ? {
        label: statusParams,
        value: statusParams
    } : { label: "Open", value: "Open" });

    const startDateParams = searchParams.get('startdate');
    const [startDate, setStartDate] = useState(startDateParams ? startDateParams : "");

    const endDateParams = searchParams.get('enddate');
    const [endDate, setEndDate] = useState(endDateParams ? endDateParams : "");

    useEffect(() => {
        getAllCandidate(statusFilter, filter);
    }, [page, filter, statusFilter, startDate, endDate]);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const {
        first_name,
        last_name,
        contact_info,
        interview_date
    } = formData;

    const handleSubmit = (e) => {
        if (!first_name.trim()) {
            errorNotification("Please enter the employee's first name.");
        } else if (!last_name.trim()) {
            errorNotification("Please enter the employee's last name.");
        } else if (!contact_info.trim()) {
            errorNotification("Please enter the employee's contact information.");
        } else if (interview_date.trim() && !validator.isDate(interview_date, new Date())) {
            errorNotification("Please Enter valid Interview date.");
        } else if (!appliedPosition) {
            errorNotification("Please select the applied position.");
        } else if (!experience) {
            errorNotification("Please select the candidate's experience.");
        }
        else {
            // Proceed with form submission

            addLead(formData);
        }
    }

    const addLead = async (payload) => {
        const FormData = require("form-data");
        const formData = new FormData();
        formData.append("resume_file", resumeFile);
        for (const key in payload) {
            if (payload[key] !== "" && payload[key] !== null) {
                formData.append(key, payload[key]);
            }
        }
        try {
            const config = {
                method: "post",
                url: `${ADD_CANDIDATE_LEAD}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        successNotification(res.data.message);
                        setFormData(defaultFormData);
                        setAppliedPosition(null);
                        setExperience(null);
                        setListShow(false);
                        getAllCandidate();
                        setStartDate("");
                        setEndDate("");
                        setFilter();
                        setResumeFile(null);
                        setStatusFilter({ label: "Open", value: "Open" });
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        }
    };


    const getAllCandidate = async (statusFilter, currentFilter) => {
        try {
            setLoading(true);
            let apiUrl = `${GET_CANDIDATE_LEAD}?page=${page}&limit=${pageSize}`;
            if (statusFilter && statusFilter.value !== "All") {
                apiUrl += `&status=${statusFilter.value}`;
            }
            if (currentFilter && currentFilter.value !== "All") {
                apiUrl += `&stage=${currentFilter.value}`;
            }
            if (startDate !== '') {
                apiUrl += `&start_date=${startDate}`;
            }
            if (endDate !== '') {
                apiUrl += `&end_date=${endDate}`;
            }
            const res = await getRequest(apiUrl, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setAllCandidate(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
            } else {
                setAllCandidate([]);
            }
            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    };

    //handle add btn click
    const handleAdd = () => {
        setListShow(true);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "image/jpeg",
                "image/png",
            ];
            if (validTypes.includes(file.type)) {
                setResumeFile(file);
            } else {
                errorNotification("Please upload a valid PDF, Word document, or image file.");
                e.target.value = null;
            }
        }
    };

    const removeFile = () => {
        setResumeFile(null);
    };

    const handleView = (item) => {
        navigate(`/candidate-leads/client-details/${item.id}`);
    }

    const positionArray = [
        "Android",
        "iOS",
        "PHP",
        "Python",
        "BDE",
        "HR",
        "React Native",
        "Flutter",
        "Designer",
        "QA",
        "Mobile",
        "ReactJS",
        "NodeJS",
        "Dot net",
        "Angular.js",
        "Android intership",
        "iOS intership",
        "PHP intership",
        "Python intership",
        "React Native intership",
        "Flutter intership",
        "QA-internship",
        "NodeJS intership",
        "ReactJS internship",
        "Other"
    ]

    const positionOptions = positionArray.map((position) => ({
        label: position,
        value: position,
    }));

    const statusArray = ["All", "Open", "Closed"];
    const statusOptions = statusArray.map((status) => ({
        label: status,
        value: status
    }));

    const stageFilterArray = [
        "All",
        "Interview Scheduled",
        "Rejected",
        "Selected",
        "Offer Made",
        "Not Interested",
        "Hired",
        "No Response",
        "Future Lead",
        "Offer Declined"
    ]

    const filterOptions = stageFilterArray.map((source) => ({
        label: source,
        value: source
    }));

    const experienceArray = [
        "Fresher",
        "Internship finished",
        "3 month Intership",
        "0",
        "6+ months",
        "1", "1.5", "2", "2.5", "3", "3.5", "4", "5", "5+"
    ]

    const experienceOptions = experienceArray.map((source) => ({
        label: source,
        value: source
    }));

    const formatTo12HourTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        let hours12 = parseInt(hours, 10);
        const period = hours12 >= 12 ? 'PM' : 'AM';
        hours12 = hours12 % 12 || 12;
        const formattedHours = hours12.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className=" candidate-leads-section bg-[#fff] rounded-[12px]">
            <div className="candidate-leads-subsection">
                <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row justify-between">
                    {role_accesses && (

                        <div className=" mx-3 mt-3">

                            <button
                                onClick={handleAdd}
                                className="add-candidate-leads "
                                disabled={!role_accesses}
                            >
                                + Add Candidate Leads
                            </button>
                        </div>
                    )}
                    <div className="flex flex-wrap lg:flex-row gap-3 items-center">
                        <Select
                            className="basic-single !min-w-[175px]  text-capitalize "
                            classNamePrefix="select"
                            placeholder="Status Filter"
                            name="status"
                            options={statusOptions}
                            value={statusFilter}
                            onChange={(selectedOption) => {
                                const currentParams = Object.fromEntries(searchParams.entries());
                                const updatedParams = {
                                    ...currentParams,
                                    pageno: 1,
                                    status: selectedOption.value,
                                };
                                setSearchParams(new URLSearchParams(updatedParams));
                                setStatusFilter(selectedOption);
                                setPage(1);
                            }}
                        />

                        <Select
                            className="basic-single !w-[175px] text-capitalize "
                            classNamePrefix="select"
                            placeholder="Stage Filter"
                            name="stage"
                            options={filterOptions}
                            value={filter}
                            onChange={(selectedOption) => {
                                const currentParams = Object.fromEntries(searchParams.entries());
                                const updatedParams = {
                                    ...currentParams,
                                    pageno: 1,
                                    stage: selectedOption.value,
                                };
                                setSearchParams(new URLSearchParams(updatedParams));
                                setFilter(selectedOption);
                                setPage(1);
                            }}
                        />
                        <div className="w-full sm:w-auto">
                            <input
                                className="form-control w-full !m-0"
                                type="date"
                                value={startDate}
                                autoComplete="off"
                                onChange={(e) => {
                                    const currentParams = Object.fromEntries(searchParams.entries());
                                    const updatedParams = {
                                        ...currentParams,
                                        pageno: 1,
                                        startdate: e.target.value,
                                    };
                                    setSearchParams(new URLSearchParams(updatedParams));
                                    setStartDate(e.target.value);
                                    setPage(1);
                                }}
                                // webkitdirectory
                            />
                        </div>
                        <p className="">To</p>
                        <div className="w-full sm:w-auto">
                            <input
                                className="form-control w-full !m-0"
                                type="date"
                                value={endDate}
                                autoComplete="off"
                                onChange={(e) => {
                                    const currentParams = Object.fromEntries(searchParams.entries());
                                    const updatedParams = {
                                        ...currentParams,
                                        pageno: 1,
                                        enddate: e.target.value,
                                    };
                                    setSearchParams(new URLSearchParams(updatedParams));
                                    setEndDate(e.target.value);
                                    setPage(1);
                                }}
                                // webkitdirectory
                            />
                        </div>
                    </div>
                </div>
                {allCandidate?.length === 0 ? (
                    <div className="no__transcation">
                        <img src={noDataFound} alt={noDataFound} loading="lazy" />
                    </div>
                ) : (

                    <div className="candidate-leads-listing">
                        <div className="candidate-leads-data pb-4">
                            <div className="d-flex align-items-start  justify-content-between client__header min-h-[600px] overflow-x-scroll">
                                <table className="table candidate-leads-list-table">
                                    <thead className="report__header__border">
                                        <tr className="border-style">
                                            <th
                                                scope="col"
                                                className="font__weight  "
                                            >
                                                Lead Id
                                            </th>
                                            <th
                                                scope="col"
                                                className="font__weight "
                                            >
                                                First Name
                                            </th>
                                            <th scope="col" className="font__weight ">
                                                Last Name
                                            </th>
                                            <th scope="col" className="font__weight">
                                                Contact Info
                                            </th>
                                            <th scope="col" className="font__weight">
                                                Applied Position
                                            </th>
                                            <th scope="col" className="font__weight">
                                                Experience(years)
                                            </th>
                                            <th scope="col" className="font__weight">
                                                Interview Date
                                            </th>
                                            <th scope="col" className="font__weight">
                                                Interview Time
                                            </th>
                                            <th scope="col" className="font__weight">
                                                Added Date
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCandidate.map((item, index) => (
                                            <tr key={index}>
                                                <td className="table_tr_style align-middle">{item?.lead_id ?? '--'}</td>
                                                <td className="table_tr_style align-middle text-capitalize">{item?.first_name ?? '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.last_name ?? '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.contact_info ?? '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.applied_position ?? '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.experience_year ?? '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.interview_date ? dayjs(item?.interview_date).format("DD-MM-YYYY") : '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.interview_time ? formatTo12HourTime(item?.interview_time) : '--'}</td>
                                                <td className="align-middle text-capitalize">{item?.createdAt ? dayjs(item?.createdAt).format("DD-MM-YYYY") : '--'}</td>
                                                <td className="text-center align-middle d-flex justify-content-end !border-b-0 ">
                                                    {role_accesses && (
                                                        <button
                                                            className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
                                                            onClick={() => handleView(item)}
                                                            disabled={!role_accesses}
                                                        >
                                                            View
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {
                                <CustomPagination
                                    page={page}
                                    pageInformation={pageInformation}
                                    setPage={setPage}
                                    totalPages={pageInformation?.last_page}
                                />
                            }
                        </div>
                    </div>
                )}
                <Modal
                    className="candidate-leads-modal"
                    title="Add Candidate Leads"
                    onClose={() => {
                        setListShow(false);
                    }}
                    show={listShow}
                >
                    <div className="flex flex-col px-2.5 gap-6 add__candidate-leads__form" onSubmit={handleSubmit}>
                        <div className="">
                            <h6 className="modal-heading">First Name</h6>
                            <input
                                placeholder="Enter you name"
                                name="first_name"
                                className="candidate-leads-name text-capitalize "
                                type="text"
                                value={formData.first_name}
                                autoComplete="off"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="">
                            <h6 className="modal-heading">Last Name</h6>
                            <input
                                placeholder="Enter your name"
                                className="candidate-leads-name text-capitalize "
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                autoComplete="off"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="">
                            <h6 className="modal-heading">Contact Info</h6>
                            <input
                                placeholder="Contact Info "
                                className="candidate-leads-name"
                                type="number"
                                name="contact_info"
                                value={formData.contact_info}
                                autoComplete="off"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="">
                            <h6 className="modal-heading">Applied Position</h6>
                            <Select
                                options={positionOptions}
                                className="basic-single"
                                classNamePrefix="select"
                                value={appliedPosition}
                                placeholder="Applied Position"
                                name="applied_position"
                                onChange={(e) => {
                                    formData.applied_position = e.value;
                                    setAppliedPosition(e)
                                }}
                            />
                        </div>
                        <div className="">
                            <h6 className="modal-heading">Experience(years)</h6>
                            <Select
                                options={experienceOptions}
                                className="basic-single"
                                classNamePrefix="select"
                                value={experience}
                                placeholder="Experience(years)"
                                name="experience_year"
                                onChange={(e) => {
                                    formData.experience_year = e.value;
                                    setExperience(e);
                                }}
                            />
                        </div>
                        <div>
                            <label className="modal-heading">Interview Date</label>
                            <input type="date" className="w-full !rounded-lg p-2 border border-gray-300"
                                onChange={(e) => {
                                    setInterviewDate(e.target.value);
                                    formData.interview_date = e.target.value
                                }}
                                value={interviewDate}
                            />
                        </div>
                        <div>
                            <label className="modal-heading">Interview Time</label>
                            <input
                                type="time"
                                className="candidate-leads-name text-capitalize "
                                placeholder="Interview Time "
                                autoComplete="off"
                                value={interviewTime}
                                onChange={(e) => {
                                    setInterviewTime(e.target.value);
                                    formData.interview_time = e.target.value
                                }}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="modal-heading mb-2">Resume File</label>
                            <div className="flex items-center">
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                                    className={`w-full rounded-lg p-2 border border-gray-300 ${resumeFile ? 'hidden' : ''}`}
                                    onChange={handleFileChange}
                                />
                                {resumeFile && (
                                    <div className='relative  w-[290px] group'>
                                        <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                           onClick={removeFile}
                                        >
                                            <RxCross2 />
                                        </div>
                                        <div
                                            className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                        >
                                            <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                <div className="w-[40px] h-[40px]">
                                                    <img
                                                        src={
                                                            icons[resumeFile?.type] || URL.createObjectURL(resumeFile)
                                                        }
                                                        alt={resumeFile?.type}
                                                        className="w-[40px] h-[40px]"
                                                    />
                                                </div>
                                                <div className="w-[190px]">
                                                    <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                        {resumeFile.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    // <div className="flex items-center">
                                    //     <span className="mr-2">{resumeFile.name}</span>
                                    //     <button
                                    //         type="button"
                                    //         className="text-red-500 text-2xl"
                                    //         onClick={removeFile}
                                    //     >
                                    //         &times;
                                    //     </button>
                                    // </div>
                                )}
                            </div>
                        </div>
                        <div className="add-candidate-leads-close-section sticky pb-[13px] !border-0 bottom-[-1px]  bg-white ">
                            {role_accesses && (
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="candidate-leads-save-btn mt-3"
                                    disabled={!role_accesses}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>

                </Modal>

            </div>
        </div>
    );
};

export default CandidateLeads;
