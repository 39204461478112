import React, { useEffect, useState } from 'react'
import "./assets.css"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Edit } from "../../../../src/components/assets/Edit-icon.svg";
import MacMini_icon from '../../../components/assets/Mac_mini_icon.svg'
import { FaLaptop } from "react-icons/fa";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { BsCpu, BsDisplay } from "react-icons/bs";
import { getRequest } from '../../../components/axiosClient';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { GET_ASSETS_BY_ID } from '../../../components/utils';
function Assetsdetails() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({});

    useEffect(() => {
        getAssetDetail();
    }, [])

    const goBack = () => {
        navigate(-1);
    };

    const handleView = () => {
        navigate(`/assets/assets-details/edit-assets/${id}`);
    }

    const customIcons = {
        CPU: <BsCpu />,
        "Mac Mini": <img src={MacMini_icon} alt="Mac mini" style={{ width: '20px', height: '20px' }} />,
        Laptop: <FaLaptop />,
        Display: <BsDisplay />,
        Mobile: <CiMobile3 />,
        IMac: <MdOutlineScreenshotMonitor />
    };

    const getAssetDetail = async () => {
        try {
            const res = await getRequest(
                `${GET_ASSETS_BY_ID}?assets_id=${id}`,
                null,
                navigate
            );
            setFormData(res.data.data);
        } catch (error) {
            console.error(error);
            errorNotification(error);
        }
    };

    const item = formData?.item;

    return (
        <div className='bg-white p-[20px] rounded-[12px] flex flex-col min-h-full'>
            <div className="go__back__btn flex flex-row justify-start items-center my-2 gap-1">
                <div className="">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer !w-8" />
                </div>
                <div className='text-[#000000] leading-8 font-medium text-xl flex flex-row justify-center text-center '>
                    {formData?.code}
                </div>
            </div>

            <div className='p-[20px] rounded-[12px] border-2 mt-3 grow'>
                <div className="">
                    <div className="flex flex-row-reverse justify-between ">
                        <div className="flex flex-row-reverse lg:flex-row justify-between ">
                            <button
                                className="bg-transparent text-black h-10 w-fit interFontFamily py-2 px-3 rounded-md text-16 cursor-pointer lg:mt-0 border-2"
                                onClick={() => handleView()}
                            >
                                <div className="flex gap-2 items-center text-black">
                                    <Edit className="" />
                                    <p>Edit</p>
                                </div>
                            </button>
                        </div>

                        <div className="grid grid-cols-2 sm:grid-cols-4 grow max-w-[1000px] gap-8 text-[#6F767E]">

                            <div className="flex gap-6">
                                <span className="text-[#000] ">Item :</span>
                            </div>
                            <span className="flex flex-row justify-start items-center gap-2">{customIcons[formData?.item]}{formData?.item}</span>

                            <div className="flex gap-6">
                                <span className="text-[#000]">Assignees :</span>
                            </div>
                            <span className="">{formData?.user ? formData?.user?.first_name : "--"}</span>

                            <div className="flex gap-6">
                                <span className="text-[#000] ">Allocation :</span>
                            </div>
                            <span className={`
                              ${formData?.allocation === "Allocated"
                                    ? "text-[#6F767E] bg-[#6F767E24]"
                                    : formData?.allocation === "Available"
                                        ? "text-[#83BF6E] bg-[#83bf6e24]" : ""
                                }
                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[120px] d-flex justify-center
                            `}>{formData?.allocation}</span>


                            <div className="flex gap-6">
                                <span className="text-[#000] ">Location :</span>
                            </div>
                            <span className="">{formData?.branch?.branch_name}</span>


                            <div className="flex gap-6">
                                <span className="text-[#000] ">Status :</span>
                            </div>
                            <span className={`
                              ${formData?.is_defected === true
                                    ? "text-[#ff3131] bg-[#f5727225]"
                                    : formData?.is_defected === false
                                        ? "text-[#83BF6E] bg-[#83bf6e24]" : ""
                                }
                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[120px] d-flex justify-center
                            `}>{formData?.is_defected ? "Defected" : "Working"}</span>

                            {
                                (item === "Laptop") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">Modal :</span>
                                        {/* <span className="ml-2 text-[#6F767E]">{document?.role?.name}</span> */}
                                    </div>
                                    <span className="">{formData?.model || "--"}</span>
                                </>
                            }
                            {
                                (item === "Display") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000]">Type :</span>
                                    </div>
                                    <span className="">{formData?.display_type || "--"}</span>
                                </>
                            }
                            {
                                (item === "CPU" || item === "Laptop") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">OS :</span>
                                        {/* <span className="ml-2 text-[#6F767E]">{document?.birth_date}</span> */}
                                    </div>
                                    <span className="">{formData?.OS || "--"}</span>
                                </>
                            }
                            {
                                (item === "CPU" || item === "Laptop") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">OS Version :</span>
                                        {/* <span className="ml-2 text-[#6F767E]">{document?.birth_date}</span> */}
                                    </div>
                                    <span className="">{formData?.os_version || "--"}</span>
                                </>
                            }
                            {
                                (item === "CPU" || item === "Laptop" || item === "IMac") &&
                                <>
                                    <div className="flex">
                                        <span className="text-[#000] ">Motherboard/<br />Processor :</span>
                                        {/* <span className="ml-2 text-[#6F767E]">{document?.emergency_contact_name}</span> */}
                                    </div>
                                    <span className="">{formData?.processor || "--"}</span>
                                </>
                            }
                            {
                                (item === "CPU" || item === "Laptop" || item === "IMac") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">Graphics Card :</span>
                                    </div>
                                    <span className="">{formData?.graphic_card || "--"}</span>
                                </>
                            }
                            {
                                (item === "CPU" || item === "Laptop" || item === "Mac Mini" || item === "IMac") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">Ram :</span>
                                    </div>
                                    <span className="">{formData?.ram ? formData?.ram + " GB" : '--'} </span>
                                </>
                            }
                            {
                                (item === "CPU" || item === "Laptop" || item === "Mac Mini" || item === "IMac") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">Hard disk :</span>
                                    </div>
                                    <span className="">{formData?.hard_disk ? formData?.hard_disk + " GB" : '--'} </span>
                                </>
                            }
                            {
                                (item === "Mobile") &&
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">Company :</span>
                                    </div>
                                    <span className="">{formData?.mobile_company || "--"}</span>
                                </>
                            }
                            {
                                <>
                                    <div className="flex gap-6">
                                        <span className="text-[#000] ">Note :</span>
                                    </div>
                                    <span className="">{formData?.note || "--"}</span>
                                </>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Assetsdetails