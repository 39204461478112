import React, { useEffect, useState } from 'react'
import { GET_MY_TASK_TIMER_REQUESTS } from '../../../components/utils';
import { getRequest } from '../../../components/axiosClient';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CustomPagination from '../../../components/common/CustomPagination';
import Modal from '../Modal/Modal';
import { Col, Row } from 'react-bootstrap';

dayjs.extend(customParseFormat);

const MyTaskRequest = () => {

    const [loading, setLoading] = useState(false);
    const [requestList, setRequestList] = useState([]);
    const [pageInformation, setPageInformation] = useState({});
    const pageSize = 10;
    const [page, setPage] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const navigate = useNavigate();


    useEffect(() => {
        getRequestedList();
    }, [page])

    const getRequestedList = async () => {
        try {
            setLoading(true);
            let apiUrl = `${GET_MY_TASK_TIMER_REQUESTS}?page=${page}&limit=${pageSize}`;
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setRequestList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
            } else {
                errorNotification(res.errormessage);
                setRequestList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }

    const handleView = (item) => {
        setModalData(item);
        setIsOpen(true);
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='p-2'>
            {requestList?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div>
                    <div className='min-h-[500px] flex flex-col justify-between overflow-x-auto'>
                        <table className="table max-w-full ">
                            <thead className="">
                                <tr className="align-middle">
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Title
                                    </th>
                                    <th scope="col" className=" !border-b-[#EFEFEF] font-medium">
                                        Project
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Requested Date
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Start Time
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        End Time
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Status
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Type
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium "></th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {requestList.map((request, index) => (
                                    <tr key={index} className="align-middle">
                                        <td className="border-b-0 !border-b-[#EFEFEF] p-2 !max-w-[30vw] overflow-hidden">
                                            <div className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {request?.task?.title || "--"}
                                            </div>
                                        </td>
                                        <td>{request?.task?.project?.name || '--'}</td>
                                        <td>{dayjs(request.created_at).format('DD-MM-YYYY')}</td>
                                        <td>{request.start_time ? dayjs(request.start_time, 'HH:mm:ss').format('hh:mm A') : '--'}</td>
                                        <td>{request.end_time ? dayjs(request.end_time, 'HH:mm:ss').format('hh:mm A') : '--'}</td>
                                        <td className="table_tr_style align-middle">
                                            <div className={`${request?.status === "Accepted" ? "text-[#83BF6E] bg-[#83bf6e24]" : request?.status === "Rejected" ? "text-[#ff3131] bg-[#f5727225]" : request?.status === "Pending" ? "text-[#6F767E] bg-[#6f767e2b]" : "text-[#6F767E] bg-[#6f767e2b]"}
                                                py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[130px] d-flex justify-center`}>
                                                {request?.status}
                                            </div>
                                        </td>
                                        <td>{request.request_type || '--'}</td>
                                        <td className='text-right'>
                                            <button className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2" onClick={() => {
                                                handleView(request);
                                            }}>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {
                        <CustomPagination
                            page={page}
                            pageInformation={pageInformation}
                            setPage={setPage}
                            totalPages={pageInformation?.last_page}
                        />
                    }
                </div>
            )}
            <Modal
                show={isOpen}
                onClose={() => setIsOpen(false)}
                title="Task Timer Request Details"
                className=""
            >
                <div className='modal-body flex flex-col gap-4'>
                    <Row>
                        <Col sm={12} className='flex justify-start items-center gap-4'>
                            <label>Task Title:</label>
                            <p>{modalData?.task?.title || '--'}</p>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>Project:</label>
                            <p>{modalData?.task?.project?.name || '--'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>Requested Date:</label>
                            <p>{dayjs(modalData?.created_at).format('DD-MM-YYYY')}</p>
                        </Col>
                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>Approver:</label>
                            <p>{modalData?.action_by?.first_name && modalData?.action_by?.last_name ? (modalData?.action_by?.first_name + ' ' + modalData?.action_by?.last_name) : '--'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>Start Time:</label>
                            <p>{modalData?.start_time ? dayjs(modalData?.start_time, 'HH:mm:ss').format('hh:mm A') : '--'}</p>
                        </Col>
                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>End Time:</label>
                            <p>{modalData?.end_time ? dayjs(modalData?.end_time, 'HH:mm:ss').format('hh:mm A') : '--'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>Request Status:</label>
                            <p>{modalData?.status || '--'}</p>
                        </Col>
                        <Col sm={6} className='flex justify-start items-center gap-4'>
                            <label>Request Type:</label>
                            <p>{modalData?.request_type || '--'}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} className='flex justify-start items-center gap-4'>
                            <label>Reason:</label>
                            <p>{modalData?.reason || '--'}</p>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default MyTaskRequest