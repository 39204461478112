import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DailyUpdates.css";
import {
  errorNotification,
} from "../../../components/toast-notification/common-toast";
import { getRequest } from "../../../components/axiosClient";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { GET_DAILY_UPDATES } from "../../../components/utils";
import dayjs from "dayjs";
import clock_active from '../../../components/assets/clock_active.svg'
import clock_inActive from '../../../components/assets/clock_inActive.svg'
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";

const DailyUpdates = () => {
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const [pageInformation, setPageInformation] = useState({});
  const pageSize = 10;
  const isMounted = useRef(false);
  const scrollPositionRef = useRef(0);

  // for get all Daily Updates list API Call
  const getAllDailyUpdate = async () => {
    try {
      setFirstLoading(true);
      const API_URL = `${GET_DAILY_UPDATES}?page=${1}&limit=${pageSize}`;
      const res = await getRequest(API_URL, null, navigate);
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setData(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
        setPage(2);
      } else {
        setHasMoreData(false); // No more data available
        errorNotification("No data found");
      }
      setFirstLoading(false);
    } catch (error) {
      errorNotification(error);
      setFirstLoading(false);
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      getAllDailyUpdate(); // Call your API only once
      isMounted.current = true; // Set it to true after the initial mount
    }
  }, []);

  const fetchData = useCallback(async () => {
    if (loading || pageInformation.current_page >= pageInformation.last_page) return;

    setLoading(true);
    try {
      const API_URL = `${GET_DAILY_UPDATES}?page=${page}&limit=${pageSize}`;
      const res = await getRequest(API_URL, null, navigate);
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        const newPageData = res.data.data.page_data;
        setData((preData) => [...preData, ...newPageData]);
        setPageInformation(res.data.data.page_information);
        setPage((prevPage) => prevPage + 1);
      }

      if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
        setHasMoreData(false); // No more data available
      }

      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  }, [page, loading, hasMoreData]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        fetchData();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData]);


  const dataFormat = (date) => {
    const nowDate = dayjs();

    if (nowDate.diff(date, "hour") < 24) {
      return "Today";
    } else if (nowDate.diff(date, "day") === 1) {
      return "Yesterday";
    } else if (nowDate.diff(date, "day") > 1) {
      // Format date with day name
      return `${dayjs(date).format("dddd, DD MMM YYYY")}`;
    }
  };

  const convertSecondsToHH = (seconds) => {
    // Calculate the hours and minutes
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // Format the result in HH:MM format using Day.js
    const formattedTime = dayjs()
      .hour(hours)
      .minute(minutes)
      .format('HH:mm');

    return formattedTime;
  }

  return firstLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {data?.length === 0 ? (
        <div className="daily_updates_section bg-white rounded-[12px] p-3">
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        </div>
      ) : (
        <div className="daily_updates_section bg-white rounded-[12px] px-3 pt-3">
          {data.map((item, index) => {

            let taskNumber = 1;
            let totalSeconds = 0;

            item.tasks.forEach(project => {
              totalSeconds += parseInt(project.duration);
            });

            return (
              <div className="rounded-lg p-[14px]" key={index}>
                <table className="w-full border-transparent bg-transparent rounded-lg !overflow-hidden">
                  <thead>
                    <tr className="bg-[#F4F4F4]">
                      <th className="w-1/2 px-4 py-2 border-t-2 border-b-2 border-l-2 border-[#F4F4F4] text-[#6F767E] rounded-tl-lg text-base">
                        {dataFormat(item?.date)}
                      </th>
                      <th className="w-1/4 px-4 py-2 border-t-2 border-b-2 border-[#F4F4F4] text-[#6F767E] font-medium">Project Name</th>
                      <th className="w-[10%] px-4 py-2 border-t-2 border-b-2 border-r-2 border-[#F4F4F4] rounded-tr-lg font-medium text-[#F37D53] ">
                        <div className="flex flex-row justify-start items-center gap-2">
                          <img src={clock_active} /> {convertSecondsToHH(totalSeconds)}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-transparent" >
                    {item.tasks?.map((task, taskIndex) => {
                      let isLastRow = taskIndex === item.tasks.length - 1;
                      return (
                        <tr key={taskIndex + task.task.id} className="text-sm">
                          <td className={`px-4 py-2.5 border-b-2 border-l-2 font-medium border-[#F4F4F4] text-[#6F767E] flex flex-row justify-start  gap-2.5 ${isLastRow ? 'rounded-bl-[8px]' : ''}`}>
                            <span>{taskNumber++}</span> <span>{task?.task?.title}</span>
                          </td>
                          <td className="px-4 py-2.5 border-b-2 border-[#F4F4F4] text-[#6F767E]">{task?.task?.project?.name || '--'}</td>
                          <td className={`px-4 py-2.5 border-b-2 border-r-2 border-[#F4F4F4] text-[#6F767E] ${isLastRow ? 'rounded-br-[8px]' : ''}`}>
                            <div className="flex flex-row justify-start items-center gap-2">
                              <img src={clock_inActive} /> {convertSecondsToHH(task?.duration)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
          })}
        </div>
      )}
      {
        loading && <div>
          <div className="flex justify-center items-center h-[100px]">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default DailyUpdates;
