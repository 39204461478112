import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Edit from "../../../../src/components/assets/Edit.svg";
import disableicon from "../../../../src/components/assets/disableicon.svg";
import { Tabs, Tab } from "react-bootstrap";
import Modal from "../Modal/Modal";
import { getRequest, patchRequest } from "../../../components/axiosClient";
import { GET_EMPLOYEE_BY_ID, UPDATE_EMPLOYEE_STATUS, UPDATE_EMPLOYEE_PASSWORD } from "../../../components/utils";
import {
    errorNotification,
    successNotification,
} from "../../../components/toast-notification/common-toast";
import dayjs from "dayjs";
import { boundaryContext } from "../../../App";
import EmployeeProjects from "./EmployeeProjects";
import EmployeeDailyUpdate from "./EmployeeTimeSheet";

const EmployeeListDetails = () => {
    const { setEmployeeName } = useContext(boundaryContext);

    const [passwordModal, setPasswordModal] = useState(false);
    const [disableModal, setDisableModal] = useState(false);
    const [document, setDocument] = useState([]);
    const [status, setStatus] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();
    const id = useParams();

    //navigate to previous page
    const goBack = () => {
        navigate(-1);
    };
    const changePassword = () => {
        setPasswordModal(true);
    };
    const disableAccount = () => {
        setDisableModal(true);
    };
    const disableAccountClose = () => {
        setDisableModal(false);
    };
    const handleView = () => {
        navigate(`/employee-list/employee-details/edit-employee/${id?.id}`);
    };

    const data = {
        user_id: id?.id,
        status: status,
    };

    // for get Employee Information API Call
    const GetEmployee = async () => {
        try {
            const res = await getRequest(
                `${GET_EMPLOYEE_BY_ID}?user_id=${id?.id}`,
                null,
                navigate
            );
            if (res.data) {
                setEmployeeName(res?.data?.data?.first_name + " " + res?.data?.data?.last_name);
                setDocument(res?.data?.data);
                setStatus(res?.data?.data?.status);
            } else {
                errorNotification(res?.errormessage);
            }
        } catch (error) {
        }
    };

    // for Employee status API Call
    const EmployeeStatus = async () => {
        try {
            if (data.status === "active") { data.status = "inactive"; }
            else { data.status = "active"; }
            const res = await patchRequest(
                `${UPDATE_EMPLOYEE_STATUS}`, data, navigate
            );
            disableAccountClose();
            GetEmployee();
            successNotification(res.response.data.message);
        } catch (error) {
            errorNotification(error.message);
        }
    };

    // for Change Password API Call
    const UpdatePassword = async () => {
        if (newPassword !== confirmPassword) {
            errorNotification("Passwords do not match");
            return;
        }

        const passwordData = {
            user_id: id?.id,
            password: newPassword,
        };

        try {
            const res = await patchRequest(
                `${UPDATE_EMPLOYEE_PASSWORD}`, passwordData, navigate
            );
            setPasswordModal(false);
            setNewPassword('');
            setConfirmPassword('');
            successNotification(res.response.data.message);
        } catch (error) {
            errorNotification(error.message);
        }
    };

    useEffect(() => {
        GetEmployee();
    }, []);


    return (
        <div className="admin__program__manage admin__program__details__section admin_audit ">
            <div className="go__back__btn     ">
                <div className="mt-1 ">
                    <ArrowBackIcon onClick={goBack} loading="lazy" className="cursor-pointer" />
                </div>
            </div>
            <div className="setting__section">
                <div className="">
                    <Tabs
                        defaultActiveKey="about"
                        id="about-tabs"
                        className="nav-pills program__manage mb-3"
                    >
                        <Tab eventKey="about" title="About" className="nav-link">
                            <div className="">
                                <div className="flex flex-col lg:flex-row justify-between ">
                                    <div className="flex align-middle">
                                        <h5 className="font-semibold text-[22px] mt-[8px]">Employee Information</h5>
                                    </div>
                                    <button
                                        className="bg-[#f47e53] text-white w-fit interFontFamily py-2 px-4 rounded-md text-16 cursor-pointer  lg:mt-0"
                                        onClick={() => handleView()}
                                    >
                                        <div className="flex gap-2 items-center">
                                            <img src={Edit} alt="" className="text-white" />
                                            <p>Edit</p>
                                        </div>
                                    </button>
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 my-6">
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal">Employee ID :</span>
                                        <span className="text-orange-500 font-normal ml-2">{document?.employee_id}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Email Address :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.email}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">First Name :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.first_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Last Name :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.last_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Contact Number :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.contact_number}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Date of Birth :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.birth_date ? dayjs(document.birth_date).format("DD-MM-YYYY") : '--'}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Emergency Contact Name :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.emergency_contact_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Emergency Number :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.emergency_contact_no}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Department :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.department?.department_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Branch :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.branch?.branch_name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Employee Role :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.role?.name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Technology :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.technology?.name}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Joining Date :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.joining_date ? dayjs(document?.joining_date).format("DD-MM-YYYY") : '--'}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal">Reporting person :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">
                                            {document?.reporting_person?.first_name && document?.reporting_person?.last_name
                                                ? `${document.reporting_person.first_name} ${document.reporting_person.last_name}`
                                                : '--'}
                                        </span>
                                    </div>

                                    <div className="grid grid-cols-2 gap-2">
                                        <span className="font-normal ">Address :</span>
                                        <span className="ml-2 text-[#6F767E] font-normal">{document?.address}</span>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        {document?.leaving_date ? (
                                            <>
                                                <span className="font-normal">Leaving Date :</span>
                                                <span className="ml-2 text-[#6F767E] font-normal">{document.leaving_date ? dayjs(document?.leaving_date).format("DD-MM-YYYY") : '--'}</span>
                                            </>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row justify-end mt-28 space-y-4 lg:space-y-0 lg:space-x-4">
                                    <button
                                        className={`w-fit py-2 px-4 rounded-md text-16 cursor-pointer ${status === 'active' ? 'bg-white text-black border' : 'bg-[#f47e53] text-white'}`}
                                        onClick={disableAccount}
                                    >
                                        {status === 'active' ? 'Disable Login' : 'Enable Login'}
                                    </button>
                                    <button className="bg-[#f47e53] text-white w-fit  py-2 px-4 rounded-md text-16 cursor-pointer" onClick={changePassword}>Change Password</button>
                                    <button className="bg-[#f47e53] text-white w-fit  py-2 px-4 rounded-md text-16 cursor-pointer">Initiate Exit</button>
                                </div>
                            </div>

                            <Modal
                                className="client-modal "
                                title="Change Password"
                                onClose={() => {
                                    setPasswordModal(false);
                                }}
                                show={passwordModal}
                            >
                                <div className="modal-body add__client__form">
                                    <div>
                                        <h6 className="modal-heading">New Password</h6>
                                        <input
                                            placeholder="New Password "
                                            className="client__name text-capitalize "
                                            type="text"
                                            autoComplete="off"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <h6 className="modal-heading">Confirm Password</h6>
                                        <input
                                            placeholder="Confirm Password "
                                            className="client__name text-capitalize "
                                            type="text"
                                            autoComplete="off"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="add__close__section mt-3">
                                        <button
                                            type="button"
                                            className="client__save__btn w-[100%]"
                                            onClick={() => { UpdatePassword(); }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                className="client-modal "
                                title="Please Confirm"
                                onClose={() => {
                                    setDisableModal(false);
                                }}
                                show={disableModal}
                            >
                                <div className="modal-body add__client__form">
                                    <div className="text-center">
                                        <div className="flex justify-center mb-4">
                                            <div className="">
                                                <img src={disableicon} alt="" />
                                            </div>
                                        </div>
                                        <p className="text-[#33383F] mb-6 font-medium capitalize ">
                                            Are you sure you want to disable <span className="text-[#f47e53]">unikwork</span> login for <span className="font-bold text-[#000000]">{document?.first_name} {document?.last_name}</span>
                                        </p>
                                        <div className="flex justify-center space-x-4">
                                            <button className="bg-[#ECEDF0] text-[#6F767E] px-[13px] py-[10px] border-0 rounded-[6px] font-medium w-[100%]"
                                                onClick={disableAccountClose}
                                            >
                                                Cancel
                                            </button>
                                            <button className="client__save__btn w-[100%]"
                                                onClick={() => { EmployeeStatus(); }}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </Tab>
                        <Tab
                            eventKey="project"
                            title="Project"
                        // className="nav-link"
                        >
                            <EmployeeProjects />
                        </Tab>
                        <Tab
                            eventKey="time-sheet"
                            title="Time Sheet"
                        // className="nav-link"
                        >
                            <EmployeeDailyUpdate />
                        </Tab>
                    </Tabs>
                    <div className="d-flex justify-content-end mt-4"></div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeListDetails;
